import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import navigationServices from '../../services/navigationServices';

const fullText_whatDocumentsToBring: React.FC = () => {
    return (
        <div>

            <p>В данной статье описан минимальный пакет документов, который необходим для родов в Аргентине и дальнейшем получении документов <b>только на ребенка</b>. Процесс документов на ребенка <Link to="/blog/kids-documents-explained/"

                target="_blank" rel="noopener noreferrer">описан в статье про детские документы.</Link></p>

            <p>Если у вас в планах после родов легализация в Аргентине с получением ПМЖ, то пакет документов будет более расширенный и основательный.</p>

            <p>Итак, документы, которые необходимо взять с собой если вы выбрали роды в Аргентине:</p>

            <p>1) <strong>загранпаспорта будущих родителей</strong><br /><br />Переводить паспорта не нужно. Как общая рекомендация, загранпаспорта должны быть действительны как минимум еще 6 месяцев со дня поездки.</p>

            <p>2) <strong>справка о сроке беременности</strong> <br /><br />Если вы летите на поздних сроках, необходимо будет получить такое разрешение у своего врача, который ведёт беременность.<br /><br />К справке есть определенные требования, в зависимости от авиакомпании. Подробнее по авиакомпаниям и требованием <Link to="/blog/airline-companies-to-fly-with/"

                target="_blank" rel="noopener noreferrer">есть статья в блоге.</Link></p>

            <p>3) <strong>обменная карта</strong><br /><br />Можно не переводить обменную карту на испанский, с переводом прекрасно справляется онлайн - переводчик.<br /><br />Как правило, вам все же нужно будет сдать все анализы на месте по прилёту, но <strong>врач должен быть в курсе обо всех особенностях вашей беременности</strong>.</p>

            <p>Этих документов будет достаточно, если вы планируете прилететь, родить ребенка, получить на него аргентинские документы и вернуться обратно без дальнейшей легализации в Аргентине.</p>
        </div >
    )
}

export default fullText_whatDocumentsToBring;