import React from 'react';
import { Link } from 'react-router-dom';
import navigationServices from '../../services/navigationServices';

const fullText_12ReasonsWhyArgentinaForGivingBirth: React.FC = () => {
    return (
        <div>
            <p>Рождение ребенка - одно из самых важных событий в жизни каждого человека. Каждая будущая мама всегда хочет, чтобы роды прошли идеально, поэтому очень тщательно подходит к выбору врача, клиники и даже страны, где появится на свет ее малыш.</p>
            <p>Вот 12 причин для того, чтобы выбрать Аргентину для родов:</p>
            <ol>
                <li><strong>Гражданство Аргентины,</strong> которое малышу присваивается автоматически по праву почвы, а <Link to="/blog/argentina-citizenship-after-giving-birth/"

                    target="_blank" rel="noopener noreferrer">родители могут претендовать на получение гражданства Аргентины сразу после рождения малыша, нет необходимости ждать 2 года или больше</Link>, как в других странах Латинской Америки. Гражданство Аргентины дает возможность путешествовать в более, чем 170 стран без оформления визы, а визу в США выдают сразу на 10 лет.</li>
                <br />
                <li><strong>Документы для родителей.</strong> Сразу после родов родители могут получить перманентную резиденцию (DNI) сроком на 15 лет. Такой документ открывает много возможностей для жизни в Аргентине. Открыть свой бизнес, открыть счет в банке, путешествовать по любым странам Южной Америки, входящих в MERCOSUR - все это возможно при наличии DNI.</li>
                <br />
                <li><strong>Качественное медицинское обслуживание</strong>. Аргентина славится своей системой здравоохранения, а врачи и акушеры в тут обладают высокой квалификацией и опытом, постоянно повышают свою квалификацию и относятся очень внимательно к своим пациентам, стараясь найти индивидуальный подход к девушкам и сделать процесс родов максимально комфортным.</li>
                <br />
                <li><strong>Современное качественное оборудование</strong>. Сегодня многие госпитали Аргентины оснащены по последнему слову техники.</li>
                <br />
                <li><strong>Партнерские роды</strong>. В Аргентине высоко развита культура партнерских родов. Для того чтобы присутствовать на родах, мужу/партнеру нет необходимости сдавать бесконечное количество анализов. Кроме того, партнерские роды также практикуются при кесаревом сечении. Это дает будущей маме возможность получить необходимую поддержку. А также в гостпитале друзья и родные могут <strong>навещать вас круглосуточно.</strong></li>
                <br />
                <li><strong>Партнер будет рядом и после родов</strong>. В большинстве госпиталей предусмотрено место, где будет отдыхать папа, давая ему возможность быть рядом с молодой мамой в самые сложные первые дни после рождения малыша.</li>
                <br />
                <li><strong>Трехразовое питание</strong> для мамы в госпитале. Почти сразу после родов вам принесут покушать. Не будет необходимости ждать 8 утра, чтобы покушать со всеми. Кроме того, у вас будет возможность выбрать обед и ужин из меню. И это все входит в стоимость госпиталя.</li>
                <br />
                <li><strong>Минимальное количество анализов для беременной</strong>. Никто не будет вас заставлять сдавать кровь и мочу каждый день и делать УЗИ каждую неделю. В Аргентине врач попросит вас сдать только те анализы, которые вам действительно необходимы.</li>
                <br />
                <li><strong>Помощь в налаживании грудного вскармливания</strong>. В каждой клинике есть консультанты по грудному вскармливанию, которые приходят на помощь в любое время дня и ночи по первому зову. Кроме того, сотрудники клиники помогают и после выписки в течение первого месяца жизни малыша.</li>
                <br />
                <li><strong>Благоприятный климат.</strong> В Аргентине нет суровой зимы, которая длится от трех месяцев до полугода. Минимальная температура зимой может опускаться до 4‑х градусов, но это длится не так долго, а жаркая погода держится не больше месяца в году. Зато во многих домах есть бассейн, где можно охладиться в жаркую погоду. Даже в холодные зимние месяцы на улицах Аргентины можно наблюдать зеленые пальмы.</li>
                <br />
                <li><strong>Невысокая стоимость жизни</strong>. <Link to="/blog/types-of-rent/"

                    target="_blank" rel="noopener noreferrer">Аренда жилья</Link> и продукты в Аргентине дешевле, чем в других странах. Не говоря уже о том, что из-за <Link to="/blog/cheaper-life-argentina-blue-official/"

                        target="_blank" rel="noopener noreferrer">разрыва официального и неофициального курсов доллара</Link> со временем может получаться покупать больше товаров и услуг за то же количество долларов.</li>
                <br />
                <li><strong>Использование криптовалют</strong>. В Аргентине очень развита культура использования криптовалют. Никогда не будет проблем вывести деньги из криптокошелька для комфортного пребывания здесь.</li>
            </ol>
            <p>Рождение ребенка в Аргентине имеет множество преимуществ. Выбирая Аргентину для родов, вы можете быть уверены в безопасности и комфорте, а также в том, что ваш малыш получит лучшие стартовые условия для жизни.</p>

            <p>Несмотря на все это, <Link to="/blog/challenges-in-argentina-to-give-birth/"

                target="_blank" rel="noopener noreferrer">роды в Аргентине имеют свои сложности и трудности</Link>.</p>

        </div>
    )
};
export default fullText_12ReasonsWhyArgentinaForGivingBirth;