import ScrollAnimation from "../ScrollAnimation";
import CustomContactForm from "../contactForm/CustomContactForm"
import "./ImageWithContactFormStyles.css"

interface ImageWithContactFormProps {
    headerText: string;
    indexOfImage: number;
}

const ImageWithContactForm: React.FC<ImageWithContactFormProps> = (props) => {
    return (
        <ScrollAnimation direction={'left'} displayedThreshold={0.2} customClassName='replace-slide-animation'>
            <section className="image-with-contact-section" style={{
                backgroundImage: `url(${require(`../../assets/landingPage/section_${props.indexOfImage}.jpg`)})`
            }}>
                <div className='image-and-form-container'>
                    <h2 className="image-with-contact-heading">{props.headerText}</h2>

                </div>

                <div className="image-with-contact-form-wrapper">
                    <ScrollAnimation direction={'down'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                        <CustomContactForm />
                    </ScrollAnimation>
                </div>
            </section>
        </ScrollAnimation>
    );
};

export default ImageWithContactForm;