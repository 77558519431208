import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import navigationServices from '../../services/navigationServices';

const fullText_argentinaCitizenshipAfterGivingBirth: React.FC = () => {
    return (
        <div>
            <p>Вы наверное могли где-то услышать или прочитать, что <i>Аргентина является страной с самым быстрым получением гражданства особенно после родов в Аргентине</i>.</p>
            <p>Давайте посмотрим верно ли это утверждение и что вообще можно ожидать.</p>
            <p>Правовые моменты о гражданстве Аргентины как таковые регулируются законом 346 от 1869 года (<strong>Ley 346/1869</strong>) - ознакомиться с ним можно <a target="_blank" rel="noopener noreferrer" href="https://www.argentina.gob.ar/normativa/nacional/ley-346-48854/actualizacion">здесь</a>.</p>
            <p>Интересно то, что в Аргентине гражданство находится в ведении Федеральных Судов, а не Миграционной службы, как это происходит в большинстве стран. Для каждого родителя будет начато отдельное судебное дело по гражданству и ход дел может идти по-разному. Какого-то одного группового файла/дела нет.</p>
            <p>Если почитать букву закона, то четко написано, что для того, чтобы претендовать на натурализацию в качестве аргентинца, то нужно прожить в стране 2 года среди прочих условий. Про наличие детей аргентинцев ничего в документе нет.</p>
            <p>Но как показывает практика, наличие детей аргентинцев позволяет "обойти" требование нахождения 2 лет на территории страны и судьи дают ход судебному делу по гражданству родителям.</p>
            <p>Немного подробнее о "дать ход судебному делу".</p>
            <p>Если начать дело о гражданстве без детей аргентинцев и без нахождения 2 лет в стране, то суд рассмотрит такой запрос и скорее всего откажет даже в запуске судебного дела, так как не выполнены условия для самого запуска дела.</p>
            <p>А вот если начать дело о гражданстве без нахождения 2 лет в стране, но с ребенком аргентинцем и ваш начальный пакет документов для гражданства в порядке, то судебное дело будет запущено (произойдет Inicio) и дальше по алгоритму будут начаты административные процедуры и запросы, которые прописаны в законе о гражданстве.</p>
            <p>На данном этапе уже видно, что просто так паспорта Аргентины никто не раздает, как только сошли с трапа самолета. И после рождения ребенка в госпиталь тоже никто не принесет родителям гражданство Аргентины.</p>
            <hr />
            <p><strong>Один из самых частых вопросов</strong>: <i>а можно ли вот этот весь процесс по гражданству запустить и покинуть Аргентину и приехать уже только на клятву и получение финального документа?</i></p><p><b>Ответ:</b> Так делать точно не рекомендуется, если не будете находиться в стране 6-7 месяцев в году - возникнет много вопросов. И заодно узнаете для себя интересную особенность предоставления в суд свежей справки о несудимости из страны происхождения при каждом новом въезде в Аргентину.</p>
            <hr />
            <p>Дальше по процессу судья может запросить разные уточняющие документы, например, источник легальных доходов в Аргентине. После уточнений, начнутся отправляться запросы в различные инстанции в Аргентине, например Миграционную службу, чтобы понять легальность нахождения в стране, а заодно и посмотреть сколько месяцев в году вы находитесь в стране и не покинули ли ее.</p>
            <p>Отмечу, что гражданство Аргентины по натурализации может быть отозвано/отменено, если вскроется факт мошенничества или предоставления недостоверных данных. Например, сокрытие судимости или указание фиктивного источника доходов или предоставление "липовых" документов. Попытка подкупа федерального судьи даже не должна возникать в уме как опция или какой-то вариант.</p>
            <hr />
            <p>По срокам получения гражданства что-то гарантировать и давать прогнозы очень сложно, но <strong>закладывайте от 1 года и больше</strong>.</p>
            <hr />
            <p>Жребием будет определен суд и судья для вашего дела, он и будет вести его. У некоторых этап уточнений может длиться месяцами, у других судей все может идти побыстрее. В некоторых случаях, ответы из инстанций могут идти очень и очень долго, например из Интерпола (да, запрос отправляется и туда тоже, чтобы убедиться, что вы не в международном розыске). Где-то секретари могут "забыть" прикрепить ответы из инстанций или вообще "забыть" запросить эти ответы. Иногда и на судью и жалобу написать нужно, чтобы взбодрить процесс.</p>
            <p>Вы можете конечно заниматься всем процессом сами от начала до конца, никто не говорит, что это невозможно. Просто имейте в виду, что вы столкнетесь с испанским языком и его будет много. Также будет много походов в суд донести бумажки, предоставить уточнения. Все это также на испанском. Можете столкнуться с "забывчивостью" секретарей, как описано выше. И все тот же испанский. В таких случаях ваше дело о гражданстве может растянуться на очень и очень долгий срок.</p>
            <p><Link to="/"
                target="_blank" rel="noopener noreferrer">В наше сопровождение родов</Link> <strong>не входит получение гражданства Аргентины</strong>.</p>
            <p>В случае с получением гражданства Аргентины, <strong>наша настоятельная рекомендация это воспользоваться услугами профессионального адвоката по гражданству Аргентины</strong>, который будет ввести ваше судебное дело. Выше описаны лишь некоторые ситуации, с которыми можете столкнуться, но нюансов гораздо больше и адвокат будет знать, как разрешать спорные или странные ситуации.</p>
            <p>Вы можете найти адвокатов самостоятельно, но <strong>нашим клиентам мы можем предоставить контакты проверенных адвокатов</strong>, которые действительно хорошо сделают свою работу.</p>
        </div>
    )
};
export default fullText_argentinaCitizenshipAfterGivingBirth;