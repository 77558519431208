import { useEffect, useState } from "react";
import { WorldMap, CountryContext } from "react-svg-worldmap";
import './WorldMapChartStyles.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
import { HiOutlineMagnifyingGlassMinus } from "react-icons/hi2";
import { TbZoomReset } from "react-icons/tb";
import WorldMapTooltip from "./WorldMapTooltip";

const WorldMapChart: React.FC = () => {

    const russianCountryNames: { [key: string]: string } = {
        "RU": "Россия",
        "BY": "Беларусь",
        "UA": "Украина",
        "GE": "Грузия",
        "AM": "Армения",
        "CN": "Китай",
        "AE": "ОАЭ",
        "GB": "Великобритания",
        "ES": "Испания",
        // "HK": "Гонконг",
        "KZ": "Казахстан",
        "TR": "Турция",
        "JO": "Иордания",
        "TN": "Тунис",
        "PL": "Польша",
        "RS": "Сербия"

    };

    const data = [
        { country: "RU", value: 35, },
        { country: "BY", value: 52, },
        { country: "UA", value: 2, },
        { country: "AE", value: 7, },
        { country: "AM", value: 3, },
        // { country: "HK", value: 1, },
        { country: "CN", value: 2, },
        { country: "GE", value: 3, },
        { country: "GB", value: 2, },
        { country: "ES", value: 1, },
        { country: "KZ", value: 2, },
        { country: "TR", value: 8, },
        { country: "JO", value: 1, },
        { country: "TN", value: 2, },
        { country: "PL", value: 6 },
        { country: "RS", value: 2 },

    ];

    const [highlightedCountry, setHighlightedCountry] = useState<string | null>(null);

    const getCountryStyle = (context: CountryContext<number>) => {
        if (context.countryCode === highlightedCountry) {
            return { fill: "#4C6249", stroke: "black" }
        }
        if (context.countryValue === undefined) {
            return { fill: "white", stroke: "black" }
        }
        if (context.countryValue !== undefined) {
            return { fill: "#E8B7B7", stroke: "black" }
        }

        return {}; // Default style
    };

    const [highlightedButton, setHighlightedButton] = useState<string | null>(null);

    const highlightButton = (buttonName: string) => {
        setHighlightedButton(buttonName === highlightedButton ? null : buttonName);
    };

    const [tooltipVisible, setTooltipVisible] = useState<boolean | null>(null);
    const [tooltipCountryText, setTooltipCountryText] = useState<string>("Страны");

    useEffect(() => {

    }, [tooltipVisible, tooltipCountryText]);

    const convertToRussianTooltipText = (context: CountryContext<number>): string => {
        const isoCode = context.countryCode;
        const countryName = context.countryName;
        if (russianCountryNames[isoCode]) {
            return `${russianCountryNames[isoCode]} 👶 ${context.countryValue}`
        } else {
            return `${countryName} 👶 ${context.countryValue}`
        }
    };

    const onClickFunction = (context: CountryContext<number>): void => {
        if (context.countryValue === undefined) {
            setTooltipVisible(null);
            setHighlightedCountry(null);
        } else {
            if (convertToRussianTooltipText(context) === tooltipCountryText) {
                if (tooltipVisible !== null) {
                    setTooltipVisible(null);
                    setHighlightedCountry(null);
                } else {
                    setHighlightedCountry(context.countryCode as string);
                    setTooltipVisible(true);
                }
            }
            else {
                setTooltipCountryText(convertToRussianTooltipText(context));
                if (tooltipVisible === true) {
                    setHighlightedCountry(context.countryCode as string);
                } else {
                    setHighlightedCountry(context.countryCode as string);
                    setTooltipVisible(true);
                }
            }
        }
    };

    return (
        <div className="map-section-container">
            <h2 className='map-section-heading-text'>География моих клиентов</h2>
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}

            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                        <div className="zoom-button-container">
                            <div onClick={() => zoomIn()} className="map-zoom-button"><HiOutlineMagnifyingGlassPlus /></div>
                            <div onClick={() => zoomOut()} className="map-zoom-button"><HiOutlineMagnifyingGlassMinus /></div>
                            <div onClick={() => resetTransform()} className="map-zoom-button"><TbZoomReset /></div>
                        </div>
                        <WorldMapTooltip text={tooltipCountryText} visible={tooltipVisible} />
                        <TransformComponent>
                            <WorldMap
                                color="blue"
                                // title="Я сопроводила роды семьям из разных стран"
                                size="xl"
                                data={data}
                                tooltipTextFunction={() => ""}
                                onClickFunction={onClickFunction}
                                styleFunction={(context) => getCountryStyle(context)}
                            />
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
        </div>

    );
};

export default WorldMapChart;