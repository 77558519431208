import React from 'react';
import "./StoriesOfGivingBirthPreTextStyles.css"

const StoriesOfGivingBirthPreText: React.FC = () => {
    return (
        <div className='container-stories-of-giving-birth'>
            <hr />
            <p>Статьи об историях родов в Аргентине <strong>не имеют цели</strong> напугать или как-то поменять решение о родах в Аргентине. <strong>Это реальные истории</strong> настоящих людей, которых я сопровождала. События описаны без упоминания имен, фамилий, дат и прочих персонализированных деталей.</p>

            <p>Очень важно понимать, что с точки зрения медицины, роды - очень ответственное и серьезное мероприятие. Тем более в другой стране, где говорят на другом языке и другие протоколы ведения беременности, не говоря уже о прочих нюансах. Помните, что <strong>выбирая сопровождение родов</strong>, вы также выбираете, <strong>кому доверите жизнь</strong> в критических ситуациях в абсолютно незнакомой для вас стране.</p>
            <hr />
        </div>
    );
};

export default StoriesOfGivingBirthPreText;