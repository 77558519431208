import React from 'react';
import navigationServices from '../../services/navigationServices';
import { Link } from 'react-router-dom';

const fullText_optimizeYourBudget: React.FC = () => {
    return (
        <div>
            <p>Аргентина всегда считалась бюджетной страной, в которой вполне комфортно жить иностранцам, особенно имеющим доход в другой стране. В Буэнос-Айрес и другие города огромной страны туристы приезжали из соседних стран для путешествий и для того, чтобы бюджетно купить продукты. В конце 2023 года ситуация немного изменилась. Причиной этому стали политические изменения внутри страны. <Link to="/blog/cheaper-life-argentina-blue-official/"

                target="_blank" rel="noopener noreferrer">Неофициальный курс доллара стоял на месте в то время как цены в магазинах в песо расти не переставали</Link>. Сложилась ситуация, когда за бОльшее количество долларов можно было купить меньше еды. Такие пертурбации сделали жизнь в Аргентине менее бюджетной и комфортной.</p>

            <p>На данный момент финансовая ситуация начинает быть похожей на ту, что была раньше. Если эта тенденция продолжится, то комфортная жизнь вернется. Однако мы должны помнить, что в данный момент делать прогнозы на долгосрочную перспективу достаточно сложно.</p>

            <p>Чтобы комфортно родить в Аргентине и не думать, хватит ли денег, нужно планировать бюджет. Я всегда говорю, <Link to="/blog/give-birth-in-argentina-how-much-budget/"

                target="_blank" rel="noopener noreferrer">что сумма для комфортных родов в Аргентине, составляет 20000-250000 долларов США</Link> с учетом перелета, проживания и всех остальных расходов. Этого вполне хватить для того, чтобы спокойно и комфортно жить в Аргентине в течение трех месяцев, не беспокоясь о финансах.</p>

            <p>Можно ли уменьшить конечный чек на роды в Аргентине? Можно. Я живу в Аргентине уже более 20 лет, и знаю, как сэкономить на жизни в этой стране.</p>
            <p>Я сразу должна сказать, что я НЕ рекомендую экономить на здоровье, клинике и враче. Я не рекомендую пропускать приемы врачей, не делать УЗИ и пропускать необходимые анализы ради экономии.</p>

            <p>На медицинских расходах все же можно сэкономить, например, выбрав клинику, которая работает по системе “все включено”. Как правило, в таких клиниках ведение беременности и роды уже включены в счет. Все посещения врача до родов, УЗИ, анализы, консультации других специалистов, роды с бригадой и послеродовое пребывание в госпитале мамы с малышом - все это уже включено. <Link to="/blog/comparing-private-and-public-hospitals/"

                target="_blank" rel="noopener noreferrer">Можно даже обратиться в государственные бесплатные госпитали, где вообще не нужно ни за что платить</Link>. Но такой вариант я не рекомендую, поскольку условия ведения беременности и родов в бесплатных госпиталях хуже, чем в частных.</p>

            <p>Если вы не хотите рожать с бригадой, а хотите приехать <Link to="/blog/choosing-your-doctor/"

                target="_blank" rel="noopener noreferrer">со своим врачом</Link>, то в этом вопросе я все же рекомендую не экономить.</p>

            <p>Что касается быта в Аргентине, то тут намного больше возможностей сэкономить.</p>

            <p><strong>Аренда жилья</strong>. Семьи, которые прилетают в Аргентине на роды, снимают квартиры, как правило, на три месяца. Стоимость такой квартиры в районах Реколета и Палермо в среднем 700 USD за месяц. Кроме этого в общую сумму необходимо включить расходы на услуги риелтора, залог, а экспенсас (коммунальные услуги) и расходы воды, газа и света, которые не всегда включены в стоимость аренды. Такой тип аренды (он называется темпораль) предполагает оплату аренды квартиры сразу за весь срок. То есть, если вы планируете снимать квартиру на три месяца, то вам придется заплатить сумму, равную стоимости аренды за <strong>три месяца + услуги риелтора + залог</strong> сразу.</p>

            <p><strong>Какой выход?</strong> Первое, что можно сделать - это рассмотреть другие районы для аренды жилья. Палермо и Реколета, Пуэрто Мадеро, Бельграно, несомненно, являются самыми престижными и безопасными районами Буэнос-Айреса, но и самыми дорогими. Такие районы как Almagro, Caballito, Villa Crespo, Villa Urquiza, Villa Devoto тоже являются спокойными и безопасными, но цены на квартиры там ниже.</p>

            <p>Если у вас есть банковская карта, выданная не в РФ, то можно воспользоваться сервисом поиска и аренды квартир AirBNB. При аренде квартиры через этот сервис, все сопутствующие расходы (эксенсас и оплата по счетчикам) уже включены, нет необходимости платить сразу крупную сумму, оплату снимают с карты помесячно, и также не надо оплачивать услуги риелтора. Многие считают, что на AirBNB месячная стоимость аренды выше, чем снять квартиру через агентство или риелтора. Однако как показывает практика, итогова стоимость аренды через сервис AirBNB с учетом сопутствующих расходов (экспенсас, оплата по счетчикам и услуг риелтора) такая же или ниже.А вот оплата происходит каждый месяц, и нет необходимости платить большую сумму сразу.</p>

            <p><strong>Магазины</strong>. В магазинах Аргентины есть привычные продукты, которые производятся и продаются в Европе: макароны Barilla, Coca-cola и другие. Обычно цена на продукты, которые импортируются из Европы, выше (иногда даже в несколько раз), чем на аналогичные продукты, произведенные в Аргентине. По качеству и вкусу Аргентинские продукты ничем не хуже импортных, а вот цена радует. Кроме этого, сетевые магазины щедры на акции и скидки: 3 по цене 2, 2 по цене 1, скидки на второй товар, и просто скидки на продукты. А если оформить скидочные карты магазинов, что скидок будет еще больше. Дисконтную карту можно оформить и без DNI.</p>

            <p><Link to="/blog/where-to-buy-food-in-ba/"

                target="_blank" rel="noopener noreferrer">Овощи и фрукты выгоднее покупать в специализированных магазинах, которые называются Verduleria</Link>. Их легко найти по соседству с домом, и там также много скидок и акций на сезонные фрукты и овощи.</p>

            <p>Такие тематические магазинчики есть и для мяса - Carniceria. Продукты там, как правило, свежее, а цена приятнее.</p>

            <p><strong>Расходы на малыша</strong>. На здоровье малыша экономить не стоит: не пренебрегайте походами к врачу и сдачей анализов ради экономии денег! Стоит упомянуть, что и на подгузниках много сэкономить не получится. Можно попробовать многоразовые пеленки, но это добавит других хлопот в виде ежедневных стирок.</p>

            <p>Если вы едете в Аргентину только на роды, и не планируете задерживаться на долго для получения документов, то можно рассмотреть возможность покупки более бюджетной коляски. Такую коляску потом можно будет достаточно легко продать на барахолке, и вернуть часть денег. То же самое касается и кроватки. Но помните, что спальное место ребенка должно быть в первую очередь безопасным!</p>

            <p>Следуя этим советам, вам удастся сэкономить на жизни в Буэнос-Айресе. В среднем, сумма, необходимая для жизни в Аргентине, может уменьшиться сразу на несколько тысяч долларов.</p>
        </div>
    )
}

export default fullText_optimizeYourBudget;