import React from 'react';
import './ReviewsFromClientsStyles.css';
import ReviewsFromClientsCarousel from './ReviewsFromClientsCarousel';

const ReviewsFromClients: React.FC = () => {
    return (
        <section className='reviewsFromClientsSection' style={{ paddingBottom: '20px' }}>
            <h2 className='reviewsFromClientsSectionHeading'>Отзывы Клиентов</h2>
            <ReviewsFromClientsCarousel />
        </section>
    );
};

export default ReviewsFromClients;