import React, { useState, useRef, useEffect } from 'react';
import "./CustomImageWithSpinnerStyles.css";

interface SpinnerProps {
    children: React.ReactNode;
    immediateLoad?: boolean; // Optional prop for immediate loading
}

const ImageSpinnerWrapper: React.FC<SpinnerProps> = ({ children, immediateLoad }) => {
    const [isLoading, setIsLoading] = useState(true);
    const imageRef = useRef<HTMLImageElement>(null);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        const img = imageRef.current;
        if (!img) return;

        if (immediateLoad) {
            img.src = img.dataset.src!;
            return; // Skip the observer if loading immediately
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    img.src = img.dataset.src!;
                    observer.disconnect();
                }
            });
        });

        observer.observe(img);

        return () => {
            if (observer && img) observer.unobserve(img);
        };
    }, [imageRef, immediateLoad]);

    return (
        <div className="spinner-container">
            {isLoading && <div className="spinner"></div>}
            {React.cloneElement(children as React.ReactElement<any>, {
                onLoad: handleImageLoad,
                style: { opacity: isLoading ? 0 : 1, transition: 'opacity 0.5s ease-in-out' },
                ref: imageRef,
                'data-src': (children as React.ReactElement<any>).props.src,
                src: immediateLoad ? (children as React.ReactElement<any>).props.src : '', // Load immediately if specified
                loading: immediateLoad ? undefined : 'lazy', // Remove lazy loading if immediate
            })}
        </div>
    );
};

export default ImageSpinnerWrapper;
