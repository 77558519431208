import React from 'react';
import './PackageInformationStandardAndPremiumEmbeddedStyles.css'
import { MdCurrencyExchange, MdSupportAgent } from "react-icons/md";
import { IoAirplaneOutline } from "react-icons/io5";
import { FaClinicMedical, FaPassport, FaRegHospital, FaSimCard } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { TbEmergencyBed } from "react-icons/tb";
import { MdBabyChangingStation } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaPlus, FaUserDoctor } from "react-icons/fa6";
import { FaCar } from "react-icons/fa";
import { MdMoreTime } from "react-icons/md";
import { BsPersonVcard } from "react-icons/bs";
import { PiBuildingApartment } from "react-icons/pi";

const PackageInformationStandardAndPremiumEmbedded: React.FC = () => {
    return (
        <div className='expandedStandardAndPremiumPackage'>
            <div className='standardAndPremiumPackageHeading'><MdSupportAgent className='standardAndPremiumPackageHeadingIcon' /> Информационная поддержка с самого первого дня, независимо от местонахождения</div>
            <div className='package-item-text'>Ещё перед вылетом мы с командой будем консультировать Вас по всем интересующим вопросам и будем оставаться на связи до момента вылета</div>

            <div className='standardAndPremiumPackageHeading'><IoAirplaneOutline className='standardAndPremiumPackageHeadingIcon' />Встреча в аэропорту </div>
            <div className='package-item-text'>Встретим в аэропорту с водителем, предоставим переходники для розеток, карту Sube и другие необходимые вещи</div>


            <div className='standardAndPremiumPackageHeading'><FaSimCard className='standardAndPremiumPackageHeadingIcon' />Оформление сим-карты</div>

            <div className='standardAndPremiumPackageHeading'><MdCurrencyExchange className='standardAndPremiumPackageHeadingIcon' />Помощь в обмене USD по выгодному курсу</div>

            <div className='standardAndPremiumPackageHeading'><PiBuildingApartment className='standardAndPremiumPackageHeadingIcon' />Помощь в подборе жилья в Буэнос-Айресе и подписании договора аренды</div>

            <div className='standardAndPremiumPackageHeading'><FaUserDoctor className='standardAndPremiumPackageHeadingIcon' />Знакомство с врачом и акушеркой</div>

            <div className='standardAndPremiumPackageHeading'><FaRegHospital className='standardAndPremiumPackageHeadingIcon' />Организация экскурсий в клиники</div>
            <div className='package-item-text'>Посмотрим клиники, которые вас заинтересуют</div>

            <div className='standardAndPremiumPackageHeading'><FaClinicMedical className='standardAndPremiumPackageHeadingIcon' />Сопровождение в банк стволовых клеток, помощь в подписании договора</div>

            <div className='standardAndPremiumPackageHeading'><IoPeople className='standardAndPremiumPackageHeadingIcon' />Сопровождение переводчика по медицинским вопросам до и после родов</div>
            <div className='package-item-text'>Сопровождение к врачам и на анализы и два визита после родов (один для мамы и один к неонатологу)</div>

            <div className='standardAndPremiumPackageHeading'><TbEmergencyBed className='standardAndPremiumPackageHeadingIcon' />Сопровождение на родах</div>
            <div className='package-item-text'>Буду с Вами от самого начала родов и до перевода в послеродовую палату</div>

            <div className='standardAndPremiumPackageHeading'><MdBabyChangingStation className='standardAndPremiumPackageHeadingIcon' />Выписка из роддома</div>
            <div className='package-item-text'>В день выписки встретим Вас с цветами, поможем подать документы на свидетельство о рождении</div>

            <div className='standardAndPremiumPackageHeading' ><FaRegAddressCard className='standardAndPremiumPackageHeadingIcon' />Получение аргентинских документов на ребенка</div>
            <div className='package-item-text'>Оформим Свидетельство о рождении, DNI и паспорт для малыша</div>

            <div className='standardAndPremiumPackageHeading'><IoDocumentTextOutline className='standardAndPremiumPackageHeadingIcon' />Апостилирование свидетельства о рождении
            </div>

            <div className='plusIconStandardAndPremiumContainer' style={{ marginBottom: "20px" }}><FaPlus className="standardAndPremiumPackagePlusIcon" /></div>

            <div className='standardAndPremiumPackageHeading' style={{ marginTop: "30px" }} ><FaCar className='premiumPackageHeadingIcon' />Предоставим автомобиль в день выписки</div>

            <div className='standardAndPremiumPackageHeading'><MdMoreTime className='premiumPackageHeadingIcon' />Оформление документов для ребенка в срочном порядке
            </div>

            <div className='standardAndPremiumPackageHeading'><BsPersonVcard className='premiumPackageHeadingIcon' />Оформление DNI для родителей </div>
            <div className='package-item-text'>Оформление DNI для других членов семьи <strong>не входит</strong> в пакет Премиум</div>

            <div className='standardAndPremiumPackageHeading'><FaPassport className='premiumPackageHeadingIcon' />Консультация по документам для получения DNI и гражданства</div>

            <div className='plusIconStandardAndPremiumContainer'><FaPlus className="standardAndPremiumPackagePlusIcon" /></div>
        </div>


    );
};

export default PackageInformationStandardAndPremiumEmbedded;