import React, { useEffect, useRef, useState } from 'react';
import "./PackageInformationVipStyles.css";
import { FaStar } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import PackageInformationStandardAndPremiumEmbedded from './PackageInformationStandardAndPremiumEmbedded';
import { FaHospitalUser } from "react-icons/fa6";
import { FaCamera } from "react-icons/fa";
import { IoMdBoat } from "react-icons/io";
import { FaBusSimple } from "react-icons/fa6";
import { TbMassage } from "react-icons/tb";
import ReactGA from 'react-ga4';

interface VipPackageProps {
    sendHeightDataToParent: (data: number) => void;
    sendIsExpandedDataToParent: (data: boolean) => void;
}

const PackageInformationVip: React.FC<VipPackageProps> = (props) => {

    const [isExpandedStandard, setIsExpandedStandard] = useState(false);
    const standardPackageRef = useRef(null as any);
    const [contentHeight, setContentHeight] = useState(0);

    const toggleStandardDetails = () => {
        ReactGA.event({
            category: 'User',
            action: `Услуги Ст + Пр v Вип - ${(isExpandedStandard ? "Свернуть" : "Раскрыть")}`
        });
        setIsExpandedStandard(!isExpandedStandard);
        measureContentHeight();
        props.sendHeightDataToParent(contentHeight);
        props.sendIsExpandedDataToParent(isExpandedStandard);
    };

    const measureContentHeight = () => {
        try {
            setContentHeight(standardPackageRef.current.scrollHeight);
            props.sendHeightDataToParent(contentHeight);
        } catch (e: any) {
            setContentHeight(0);
            // contentHeight = standardPackageRef.current.scrollHeight;
        }
    };


    useEffect(() => {
        if (standardPackageRef === undefined) { }
        else if (standardPackageRef === null) { }
        else {
            measureContentHeight();
        }
        props.sendIsExpandedDataToParent(isExpandedStandard);

    }, [isExpandedStandard]);

    // Update content height when content changes
    useEffect(() => {
        if (standardPackageRef === undefined) { }
        else if (standardPackageRef === null) { }
        else {
            measureContentHeight();
        }
    }, [standardPackageRef.current]);

    useEffect(() => { }, [contentHeight]);

    useEffect(() => { }, [isExpandedStandard])

    return (
        <div className='expandedVipPackage' >
            <div className='vipPackageHeading'><FaStar className={`vipPackageHeadingIcon`} />Все позиции пакета стандарт и Премиум</div>

            <div className='expandStandardPackageInVipContainer'>
                <button onClick={toggleStandardDetails} className='expandStandardPackageInVip'>{!isExpandedStandard ? <IoIosArrowDown className='expandStandardPackageInVipIcon' /> : <IoIosArrowUp className='expandStandardPackageInVipIcon' />}  Услуги Стандарт и Премиум</button>
            </div>

            <div className={`detailsStandard ${isExpandedStandard ? 'open' : 'closed'}`} style={{
                height:
                    !isExpandedStandard ?
                        "0px" :
                        `${contentHeight}px`
            }}
                ref={standardPackageRef}>
                <PackageInformationStandardAndPremiumEmbedded />
            </div>

            {/* <FaPlus className="vipPackagePlusIcon" /> */}

            <div className='vipPackageHeading aboveStandardAndPremiumPackage'><FaHospitalUser className='vipPackageHeadingIcon' />Оплата всех приемов у врача и анализов до родов</div>
            <div className='vipPackageServiceDescription package-item-text'>Оплата самих родов и клиники не входит в стоимость</div>

            <div className='vipPackageHeading'><FaCamera className='vipPackageHeadingIcon' />Фотосессия для малыша и родителей
            </div>

            <div className='vipPackageHeading'><IoMdBoat className='vipPackageHeadingIcon' />Организация поездки в город Тигре с экскурсией на пароходе</div>

            <div className='vipPackageHeading'><FaBusSimple className='vipPackageHeadingIcon' />Организация поездки в город Люхан с просмотром собора и обедом</div>

            <div className='vipPackageHeading'><TbMassage className='vipPackageHeadingIcon' />Два сеанса массажа </div>

        </div>
    );
};

export default PackageInformationVip;