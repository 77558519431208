import React from 'react';
import StoriesOfGivingBirthPreText from '../../components/blog/StoriesOfGivingBirthPreText';

const fullText_birthStoriesNightlyBronchospasm: React.FC = () => {
    return (
        <div>
            <StoriesOfGivingBirthPreText />
            <p>Многие будущие мамы воспринимают роды, как некий конец приключения. Ведь обычно беременность заканчивается именно родам, а вместе с родами приходит и конец всем “прелестям” третьего триместра беременности в виде болей в пояснице, отеков, невозможности нормально передвигаться и многому другому. Но на самом деле роды - это только начало. Начало новой жизни с вашим малышом. Если беременность была многоплодной, тогда ответственность вырастает.</p>
            <p>По классике жанра, беременность у мамы протекала нормально, хоть и была многоплодной. Роды тоже прошли хорошо, без экстренных ситуаций. Как это бывает обычно, малыши и мама были выписаны из госпиталя через несколько дней.</p>
            <p>Первое время дома все было хорошо. Малыши кушали, спали и занимались другими детскими делами. Но спустя примерно 1,5 месяца после родов, мама позвонила мне ночью. Она заметила что-то странное в поведении одного из малышей. Он все время спал, она не могла его добудиться, очень странно дышал, и лицо, губы и носогубный треугольник отличались по цвету. С другим малышом все было в порядке. Я попросила маму выслать мне видео, чтобы убедиться, что с малышом все хорошо, а мама возможно просто напрасно волнуется. Однако, видео мне очень не понравилось.</p>
            <p>Я попросила маму срочно взять такси или другой транспорт. Я в этом время была на связи по телефону. Сев в такси, мама дала трубку водителю, и я попросила его срочно везти из в ближайшую больницу. Я также попросила водителя помочь маме и малышу в больнице, дала инструкцию, где найти педиатрию, и что нужно сказать, что есть угроза жизни ребенка. Водитель понял всю серьезность ситуации, быстро довез семью в больницу и помог им объяснить врачам, что случилось.</p>
            <p>Состояние ребенка к моменту приезда в госпиталь было критическим, врачи поставили диагноз бронхоспазм. Но врачам удалось сделать все необходимое, чтобы спасти его жизнь. Ребенок провел в реанимации около недели, а затем был благополучно выписан домой к семье.</p>
            <p>Перед тем, как сделать выбор в пользу того или иного сопровождающего, пожалуйста, убедитесь, что он сможет быть с вами на связи, в том числе и ночью. Убедитесь, что ваш сопровождающий будет знать, к кому и куда нужно обратиться в случае непредвиденной ситуации. Перед тем, как планировать путешествие для родов в другую страну, убедитесь, что с вами на связи будет человек, разбирающийся в медицине.</p>
            <p>Сейчас семья счастливо живет вместе. И даже думать не хочется, что было бы, обратись мама за помощью на несколько часов позже.</p>
        </div>
    )
};

export default fullText_birthStoriesNightlyBronchospasm;