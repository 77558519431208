enum SiteMetaPropertyEnum {
    PlainSiteTitle = "Роды в Аргентине с Ольгой",
    HomePageSiteTitle = "Роды в Аргентине с Ольгой - Заботливое сопровождение родов",
    BlogPageSiteTitle = "Блог - Роды в Аргентине с Ольгой",
    FrequentQuestionsPageSiteTitle = "Роды в Аргентине с Ольгой - Частые вопросы",
    PageNotFoundSiteTitle = "404 - Страница не найдена",
    HomeSiteDescription = "Самые заботливые роды в Аргентине. Более 100 успешных сопровождений родов разной сложности. 30 лет в медицине и 20 лет в Аргентине",
    BlogSiteDescription = "Блог Ольги про роды в Аргентине: заметки и наблюдения о жизни в Аргентине, особенности родов, советы, нюансы, быт",
    FrequentQuestionsSiteDescription = "Ответы на часто задаваемые вопросы по родам в Аргентине",
    URLPlainSite = "https://www.rodysolgoyargentina.com",
    URLBlogSite = "https://www.rodysolgoyargentina.com/blog",
    URLFrequentQuestionsSite = "https://www.rodysolgoyargentina.com/frequent-questions",
    OgTypeWebsite = "website",
    OgTypeBlogArticle = "article",
}

export default SiteMetaPropertyEnum;