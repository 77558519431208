import React from 'react';

const fullText_cheaperLifeArgentinaBlueOfficial: React.FC = () => {
    return (
        <div>
            <p>Не секрет, что в Аргентине есть инфляция - цены в песо становятся дороже и можно заметить, что частенько ценники меняют.</p>
            <p>В 2023 почти весь год держался сильный разрыв между официальным и blue курсами. Как следствие, иностранцам было дешево жить в Аргентине в 2023.</p>
            <p>Что характерно, в 2023 году доллар по курсу blue рос немного быстрее инфляции и ценники не всегда успевали переставлять. То есть, сильная инфляция в песо не замечалась за счет обмена в песо по blue курсу доллара. За 1 доллар с течением времени вы могли купить немного больше товаров и услуг. Особо не было долларовой инфляции.</p>
            <p>С приходом нового правительства, политика немного изменилась. С 2024 года официальный курс доллара был изменен в 2 раза. Но blue курс не вырос в 2 раза - разрыв между курсами стал минимальным. Инфляция в песо никуда не делась, но и появилась долларовая инфляция. Товары и продукты дорожали, но blue курс особо не менялся. То есть с течением времени за 1 доллар, могли купить меньше услуг и товаров и ощущалась самая настоящая инфляция и Аргентина как страна стала дороже. И так было с января по июль 2024.</p>
            <p>Наступил июль 2024 и видим, что разрыв между официальным и blue курсом становится больше. 2 июля уже перевалил за 1400 песо за 1 доллар.</p>
            <p>Что это значит для тех, кто планирует рожать в Аргентине?</p>
            <p>Платные госпитали выставляют цены на роды в песо и пересматривают каждый месяц. У каждого контракта написано до какого числа цены, которые указаны в нем, актуальны. То есть из-за разрыва между официальным и blue курсами можно оплатить контракт за меньшее количество долларов, например ближе к концу месяца или к дате, которая указана в контракте.</p>
            <p>Не говоря уже о том, что за меньшее количество долларов можно будет жить комфортнее, так как цены в песо и изменение blue курса должны минимизировать долларовую инфляцию.</p>
            <p>Краткосрочная аренда квартиры обычно в долларах, поэтому там разницы особой не будет, несмотря на разрыв в курсах.</p>
            <p>Сложно сказать продлится ли этот тренд или нет, но начинает быть похожим на то, как было в прошлом году - иностранцам было финансово комфортно в Аргентине.</p>

        </div>
    )
};
export default fullText_cheaperLifeArgentinaBlueOfficial;