import React from 'react';
import { Link } from 'react-router-dom';
import BlogPostTagEnum from '../../data/enums/BlogPostsTagEnum';
import BlogArticleModel from '../../models/BlogArticleModel';
import './BlogArticlePreviewStyles.css';
import ImageSpinnerWrapper from '../CustomImageWithSpinner';
import ReactGA from 'react-ga4';
import ScrollAnimation from '../ScrollAnimation';

interface BlogArticlePreviewProps {
    blogArticleModel: BlogArticleModel;
}

const BlogArticlePreview: React.FC<BlogArticlePreviewProps> = (props) => {

    return (

        <div className="blogArticlePreview">
            <ScrollAnimation direction={'down'} displayedThreshold={0.01} customClassName='replace-slide-animation'>
                <ImageSpinnerWrapper>
                    <img src={require(`../../blogPosts/images/${props.blogArticleModel.blogImageURL}`)} alt='blog-article-preview-image' />
                </ImageSpinnerWrapper>
                <p className='article-date-published'>{props.blogArticleModel.datePublished}</p>
                <h3>{props.blogArticleModel.articleH1}</h3>
                <p>{props.blogArticleModel.articleSummary}</p>
                <Link to={`/blog/${props.blogArticleModel.blogArticleURL}/`} onClick={() =>
                    ReactGA.event({
                        category: 'User',
                        action: `Из блога -> '${props.blogArticleModel.articleTitle}'`
                    })
                } >
                    <p className='article-preview-read-more'>Читать</p>
                </Link>

                {/* <p>({questionTags.map(tag => (
                <span key={tag} style={{ marginRight: '5px' }}>{tag}</span>
            ))})</p> */}
            </ScrollAnimation>
        </div >

    );
};

export default BlogArticlePreview;
