import React from 'react';
import StoriesOfGivingBirthPreText from '../../components/blog/StoriesOfGivingBirthPreText';

const fullText_birthStoriesPostpartumBleeding: React.FC = () => {
    return (
        <div>
            <StoriesOfGivingBirthPreText />
            <p>Иногда жизнь преподносит нам сюрпризы тогда, когда совсем не ждешь. Кажется, что самое сложное уже позади, встреча с малышом произошла, и мама наслаждается материнством, и все хорошо. Но у судьбы другие планы, и она может проверить нас на прочность.</p>
            <p>Эта история о маме, которая приехала в Аргентину на роды вместе со своим страшим ребёнком. Малышу на тот момент было полтора года. Беременность протекала замечательно. Роды тоже прошли легко и хорошо. И после родов мама и малыш чувствовали себя прекрасно, и были благополучно выписаны домой. Мы еще встречались после этого, когда они приходили на прием к неонатологу, а также на оформлении документов на малыша. Ни у мамы, ни у малыша никаких жалоб не было.</p>
            <p>Но где-то через 2-3 недели после родов мама вдруг мне позвонила посреди ночи. Она была крайне обеспокоена, почти кричала от страха и просила о помощи. У неё началось очень сильное кровотечение, которое никак не могло остановиться.</p>
            <p>Действовать надо было быстро. Я позвонила в 911, объяснила ситуацию. Дала им всю необходимую информацию: когда были роды, как они прошли, назвала адрес. Все время, пока ехала скорая, я оставалась на связи с мамой. Приехала скорая и забрала девушку в больницу. Ее привезли в государственный госпиталь, где остановили кровотечение и поставили там диагноз. Оказалось, что в матке после родов были образования, которые и дали кровотечение.</p>
            <p>Без операции не обошлось. Но в больнице мама была недолго. Ее выписали буквально через 4 дня после операции. Все закончилось хорошо. Мама и ее дети сейчас счастливы и здоровы.</p>
            <p>При выборе сопровождающего на роды, спросите, останется ли она с вами на связи и после родов, и готова ли она вам помочь в экстренной ситуации. Хорошо, когда все хорошо. Но если все же с вами случилось непредвиденное, пусть с вами будет человек, который знает, что делать, и готов вам помочь.</p>
            <p>Я всегда остаюсь на связи с моими клиентами и до родов, и после родов. Они знают, что ко мне можно обратиться в экстренном случае в любое время дня и ночи.</p>
        </div>
    )
};
export default fullText_birthStoriesPostpartumBleeding;