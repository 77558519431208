import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useTrailingSlash = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== '/' && location.pathname.slice(-1) !== '/') {
            navigate(`${location.pathname}/`, { replace: true });
        }
    }, [location, navigate]);
};

export default useTrailingSlash;