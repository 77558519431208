const LoadingBlogPage = () => {

    return (
        <div id="loading-screen" className="loading-screen">
            <div id="spinner-container">
                <div id="spinner">
                </div>
            </div>
        </div>
    )
}

export default LoadingBlogPage;