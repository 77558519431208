import React from 'react';

const fullText_trustingDoctors: React.FC = () => {
    return (
        <div>
            <p>Роды - это процесс уникальный и очень эмоциональный. Женщина во время родов может испытывать широкий спектр эмоций: от волнения и радости до страха и боли. Огромную роль играет психологическое состояние, в котором будущая мама идет в роды, играет огромную роль в этом процессе. Итак, чего же ждать от ведения беременности и родов в Аргентине?</p>
            <p>Первое, о чем нужно помнить о ведении беременности, это то, что медицинские протоколы в Аргентине и России или других странах отличаются. Так, например, девушки, которые прилетают в Аргентину на роды, сдают анализы крови только один раз на 36-37 неделе, если беременность протекает нормально.</p>
            <p>До 32 недели беременности посещение врача необходимо лишь раз в месяц, а с 32 до 37 уже раз в две недели. С 37 недели будущим мамам необходимо приходить на КТГ каждую неделю. Такой график посещения врача и процедур предусмотрены для стандартных беременностей без каких-либо осложнений. Если вдруг во время беременности возникают какие-либо незначительные отклонения, например малыш плохо набирает вес или допплерография сосудов не такая хорошая, как хотелось бы, врач попросит сделать дополнительные исследования, такие как УЗИ каждую недели или даже каждые три дня, чтобы отследить динамику.</p>
            <p>Помните о том, что независимо вашего выбора клиники и госпиталя на роды, все врачи, у которых есть право принимать роды, обладают большим опытом. Только представьте, чтобы стать акушером-гинекологом нужно для начала учиться на протяжении пяти лет, а затем нужно пройти длительную практику около 4-5 лет. Только после этого врач будет допущен к родам самостоятельно. Следует также учесть, что иногда в государственных клиниках на родах могут присутствовать студенты.</p>
            <p>Очень важно доверять врачу и медперсоналу как во время беременности, так и в самих родах. Помните, что врачи Аргентины руководствуются медицинскими протоколами Аргентины, а о законах и медицине России, Беларуси, Польши и других стран они, скорее всего, никогда не слышали. Именно врач несет ответственность за вас, ваше здоровье и здоровье вашего малыша. Поверьте, врачи вам желают только добра. Они, как и вы, заинтересованы в благополучном исходе родов.</p>
            <p>Во время родов обязательно с вами будет присутствовать ваш близкий человек, который сможет вас поддержать. Более того, в клиниках вам будет разрешено ходить, лежать, стоять, петь, включать любимую музыку. Некоторые врачи и акушеры даже не против использовать ароматические масла, которые вы им предложите, чтобы облегчить ваше состояние. В клиниках Аргентины на родах делается все для того, чтобы этот процесс прошел как можно более мягко, а малыш появился на свет в атмосфере любви и доверия.</p>
        </div>
    )
};
export default fullText_trustingDoctors;