import React, { useState } from 'react';
import './QuestionAnswerStyles.css';
import { BsQuestionSquareFill } from "react-icons/bs";
import useTitleAndOgTitleMetaTag from '../../UseTitleAndOgTitleMetaTag';
import UseDescriptionAndOgDescriptionMetaTag from '../../UseDescriptionAndOgDescriptionMetaTag';
import UseOgUrlMetaTag from '../../UseOgUrlMetaTag';
import UseOgTypeMetaTag from '../../UseOgTypeMetaTag';
import UseCanonicalLinkTag from '../../UseCanonicalLinkTag';
import SiteMetaPropertyEnum from '../../../data/enums/SiteMetaPropertiesEnum';

interface QuestionModel {
    question: string;
    answer: React.ReactElement<any, any>;
}

const listOfQuestions: QuestionModel[] = [
    {
        question: "Как начать сотрудничество?",
        answer: <div>Первый шаг - это связаться со мной. Мы с вами обсудим условия сотрудничества, подпишем электронный договор. Как только договор будет подписан, мы создадим с вами чат в Whatapp, где я и моя команда будем всегда на связи с вами.</div>
    },
    {
        question: "Как проходит совпровождение?",
        answer: <div>
            <span>Сопровождение проходит в несколько этапов:</span>
            <br />
            <br />
            <span><i><strong>Знакомство</strong>:</i> Мы с вами созвонимся, обсудим условия сотрудничества. Я расскажу о том, что вас ждет в Аргентине и отвечу на все ваши вопросы.</span>
            <br />
            <br />
            <span><i><strong>До прилета</strong></i>: Мы подпишем договор о сотрудничестве, и создадим чат в WhatsApp. С этого момента мы с вами остаемся на связи, и вы сможете задавать мне и моей команде любые вопросы. Мы поможем подобрать вам авиабилеты, собрать чемодан, подскажем, какие вещи нужно привести с собой в Аргентину, а какие можно купить на месте. Мы подскажем вам, где лучше остановиться на первое время, и даже можем начать поиск квартиры на время проживания в Буэнос-Айресе.</span>
            <br />
            <br />
            <span><i><strong>Перелет</strong></i>: Во время перелета мы будем тоже поддерживать с вами связь. Нам важно знать, что у вас все хорошо.</span>
            <br />
            <br />
            <span><i><strong>Прилет</strong></i>: Мы с водителем встретим вас в аэропорту и отвезем вас в отель. В аэропорт я привезу вам необходимые вещи: сим-карты, переходники, карту Sube (для общественного транспорта), обмен долларов.</span>
            <br />
            <br />
            <span><i><strong>Пребывание в Буэнос-Айресе</strong></i>: По прилету мы сразу начнем с вами работу. Мы с вами запланируем знакомство с врачами, экскурсии в клиники, поможем с поиском квартиры. На адаптационной встрече мы расскажем вам обо всех тонкостях жизни в Аргентине. Кроме этого, мы вам подарим гайды по знакомству с Аргентиной и Буэнос-Айресом с полезными ссылками и контактами для облегчения адаптации в новой стране.</span>
            <br />
            <br />
            <span>Мы с командой будем на связи с вами 24/7. Вы можете задавать вам любые вопросы и рассчитывать на нашу помощь и поддержку по любым вопросам. Магазины для малышей, продуктовые магазины, магазины одежды, техники, канцтоваров - мы поможем Вам с любым вопросом.</span>
        </div>
    },
    {
        question: "Всем ли ставят окситоцин?",
        answer: <div>
            <span>Нет, всем подряд его не дают.</span>
            <br />
            <br />
            <span>При поступлении в клинику вам обязательно поставят венозный катетер. Это обязательная процедура в Аргентине, поскольку во время родов должен быть доступ к вене на случай, если что-то пойдет не так. Установка катетера не означает, что вам дадут окситоцин. Для этого должны быть медицинские показатели.</span>
        </div>
    },
    {
        question: "Какие показания для окситоцина?",
        answer: <div>
            <span>✔️Отхождение околоплодных вод и отсутствие схваток более 12 часов</span>
            <br />
            <br />
            <span>✔️Слабые схватки</span>
            <br />
            <br />
            <span>✔️Схватки регулярные, но длительность одной схватки недостаточна для того, чтобы матка раскрывалась дальше</span>
            <br />
            <br />
            <span>✔️При уменьшении интенсивности схваток после эпидуральной анестезии</span>
            <br />
            <br />
            <span>✔️Стимуляция родов (на 42 неделе)</span>
            <br />
            <br />
            <span>✔️После родов для сокращения матки</span>
            <br />
            <br />
            <span><strong>Важно!</strong> Доза окситоцина, применяемая врачами во время родов, является очень небольшой. Окситоцин применяют только по показаниям</span>
        </div>
    },
    {
        question: "Когда ставят эпидуральную анестезию?",
        answer: <div>Эпидуральную анестезию применяют в первой фазе родов, когда схватки становятся интенсивными и болезненными, а раскрытие шейки матки достигает 5-6 см.</div>
    },
    {
        question: "Можно ли отцу присутствовать на родах в Аргентине?",
        answer: <div>Да, можно. Для этого не требуется никакого разрешения или сдачи анализов.</div>
    },
    {
        question: "Нужно ли сдавать отцу анализы для присутствия на родах в Аргентине?",
        answer: <div>Нет, никаких анализов отцу сдавать не нужно для того, чтобы присутствовать на родах.</div>
    },
    {
        question: "Обязательно ли ставить прививки в Аргентине?",
        answer: <div><span>Да, прививки в Аргентине для детей являются обязательными.</span>
            <br />
            <br />
            <span>Но если вы не можете ставить прививки по религиозным соображениям, об этом надо сообщить неонатологу.</span></div>
    },

    {
        question: "Какие документы нужны для рождения ребенка в Аргентине?",
        answer: <div>Для рождения ребенка в Аргентине необходимы только ваши <strong>загранпаспорта</strong>.</div>
    },
    {
        question: "Какие документы нужны для оформления документов на ребенка в Аргентине?",
        answer: <div><span>✔️ Паспорта обоих родителей</span><br /><br />
            <span>✔️ Свидетельство о рождении ребенка</span><br /><br />
            <span>✔️ Белая пеленка (чтобы сфотографировать ребенка)</span></div>
    },
    {
        question: "Какие документы нужны для оформления документов для родителей?",
        answer: <div><span>✔️Загранпаспорт</span>
            <br />
            <br />
            <span>✔️Свидетельство о рождении</span>
            <br />
            <br />
            <span>✔️Справка о несудимости из страны гражданства с апостилем</span>
            <br />
            <br />
            <span>✔️Свидетельство о заключении брака</span>
            <br />
            <br />
            <span>✔️Свидетельство о рождении ребенка</span>
            <br />
            <br />
            <span>✔️DNI ребенка</span>
            <br />
            <br />
            <span>✔️Справка Domicilio (о месте жительства в Аргентине). Ее необходимо брать в отделении полиции вашего района</span>
            <br />
            <br />
            <span>Все документы, выданные не в Аргентине должны быть апостилированы в стране выдаче. Загранпаспорт не апостилируется.</span></div>
    },
    {
        question: "Можно ли перевести документы на испанский язык не в Аргентине?",
        answer: <div>Документы, которые вы привезете, должны быть переведены только <strong>в Аргентине</strong> и только <strong>официальным</strong> переводчиком.</div>
    },
    {
        question: "Где найти официальных переводчиков в Аргентине?",
        answer: <div><span>На сайте коллегии переводчиков: <a target="_blank" rel="noopener noreferrer" href='https://www.traductores.org.ar/'>traductores.org.ar</a></span><br /><br />
            <span>❗️ Моим клиентам я всегда советую проверенных официальных переводчиков, которые делают переводы быстро, качественно и по приемлемой цене.</span></div>
    },


    {
        question: "Что такое DNI?",
        answer: <div><span>DNI (Documento Nacional de Identidad) - это национальный документ удостоверения личности в Аргентине. Аналогом DNI можно считать внутренний паспорт в других странах. Родители ребенка, рожденного в Аргентине, получают право на оформление DNI на 15 лет. Это называется <strong>Residencia Permanente</strong> - не путать с гражданством Аргентины. Основанием для получения Residencia Permanente родителям после рождения ребенка в Аргентине является восстановление семьи. </span>
            <br />
            <br />
            <span>Также в Аргентине существуют различные категории DNI для иностранцев (аналог ВНЖ), который нужно продлевать каждый год или три (в зависимости от категории).</span></div>
    },
    {
        question: "Что дает DNI Аргентины?",
        answer: <div><span>Нужно пониматься, что DNI не равно гражданство Аргентины. Хотя с DNI вы не сможете путешествовать по всему миру, все же этот документ дает ряд преимуществ:</span>
            <br />
            <br />
            <span>✔️ легальный статус</span>
            <br />
            <br />
            <span>✔️ открытие счета в банке</span>
            <br />
            <br />
            <span>✔️ оформление медицинской страховки</span>
            <br />
            <br />
            <span>✔️ путешествие по странам Латинской Америки, входящих в состав MERCOSUR</span>
            <br />
            <br />
            <span>✔️ возможность официально устроиться на работу</span>
            <br />
            <br />
            <span>✔️ социальные льготы и программы</span>
            <br />
            <br />
            <span>✔️ возможность регистрации на международных платформах, где необходимо пройти верификацию с документами</span></div>
    },

    {
        question: "Когда родители могут подавать документы на получения DNI?",
        answer: <div>Как только будет готово <i>DNI ребенка</i>.</div>
    },
    {
        question: "Когда нужно оформить DNI родителям?",
        answer: <div>Когда у вас в Аргентине рождается ребенок, вы получаете право оформить DNI для себя на 15 лет <strong>в любой момент</strong>. Вы можете оформить документы как сразу после рождения малыша, так и через год-два или другое время.</div>
    },
    {
        question: "Как быстро родители могут получить DNI (ВНЖ) после рождения ребенка?",
        answer: <div>В последнее время получения ВНЖ (DNI) составляет около <i>4-х месяцев</i> после подачи.</div>
    },
    {
        question: "Какая разница между DNI и гражданством Аргентины?",
        answer: <div><span>DNI служит основным удостоверением личности в повседневной жизни в Аргентине. Его требуют при подписании контрактов, аренды жилья, получения водительских прав и многого другого. Даже для оплаты покупок в магазинах картой необходимо предъявить этот документ. Для иностранцев DNI одобряется миграционной службой, и не дает права путешествия по всему миру, а только в страны MERCOSUR.</span>
            <br />
            <br />
            <span>Гражданство Аргентины - это тоже DNI только для граждан страны и в графе национальность написано "Аргентина". Для иностранцев гражданство в свою очередь можно запросить и получить через Федеральный Суд. И уже с DNI, которое для граждан Аргентины, можно получить загранпаспорт, который дает вам право безвизового путешествия в более чем 170 стран мира, включая страны Шенген, Великобританию, Японию, а также возможность получить визу в США сразу на 10 лет. Процесс получения гражданства индивидуален и не всегда так прост, как может показаться.</span></div>
    },
    {
        question: 'Как быстро после рождения ребенка родители получат гражданство?',
        answer: <div>Процесс получения гражданства Аргентины индивидуален. Согласно текущему закону, родители могут подать документы на получение паспорта сразу после рождения ребенка. Нет необходимости ждать 2 года (с постоянным проживанием на территории страны) для подачи документов.</div>
    },
    {
        question: "Что такое эпизиотомия?",
        answer: <div>Эпизиотомия - это хирургическая процедура, применяемая во время родов, с целью расширить вход во влагалище и облегчить прохождение через него ребенка. Простыми словами, эпизиотомия - это разрез в области между влагалищем и анусом.</div>
    },
    {
        question: "Часто ли применяется эпизиотомия?",
        answer: <div>Эпизиотомия применяется при необходимости. Врачи предпочитают не делать данную процедуру, поскольку при разрыве во время родов, пострадает только один слой, в отличие от разреза, который повредит несколько слоев, которые нужно будет зашивать.</div>
    },
    {
        question: "Можно ли избежать эпизиотомии?",
        answer: <div>Можно. Для этого можно использовать превентивные методы. Например, массаж промежности во время беременности. Важно проконсультироваться с врачом по этому поводу.</div>
    },
    {
        question: "Как проходит восстановление после эпизиотомии?",
        answer: <div>Сразу после родов разрез зашьют. Область разреза будет опухшей и болезненной несколько дней. С ней можно справиться с помощью обезболивающих препаратов, которые посоветует вам ваш врач. Швы при эпизиотомии снимать не нужно, они рассосутся сами. Полное заживление может занять несколько недель.</div>
    },

];

const QuestionAnswer: React.FC = () => {

    useTitleAndOgTitleMetaTag(SiteMetaPropertyEnum.FrequentQuestionsPageSiteTitle, SiteMetaPropertyEnum.FrequentQuestionsPageSiteTitle);

    UseDescriptionAndOgDescriptionMetaTag(SiteMetaPropertyEnum.FrequentQuestionsSiteDescription, SiteMetaPropertyEnum.FrequentQuestionsSiteDescription);

    UseOgUrlMetaTag(SiteMetaPropertyEnum.URLFrequentQuestionsSite);
    UseOgTypeMetaTag(SiteMetaPropertyEnum.OgTypeWebsite);
    UseCanonicalLinkTag(SiteMetaPropertyEnum.URLFrequentQuestionsSite)

    const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);

    const toggleExpansion = (index: number) => {
        setExpandedIndexes(prevIndexes => {
            if (prevIndexes.includes(index)) {
                // If the clicked question is already expanded, collapse it
                return prevIndexes.filter(i => i !== index);
            } else {
                // If the clicked question is not expanded, expand it and keep others open
                return [...prevIndexes, index];
            }
        });
    };

    return (
        <section className='question-answer-section'>
            <h1 className='question-answer-section-heading-text'>Частые вопросы про роды в Аргентине</h1>
            <div className='question-answer-section-subheading-text'>Ниже представлены ответы на часто задаваемые вопросы по родам в Аргентине</div>
            <br />
            <div className='questions-container'>
                {listOfQuestions.map((q, index) => (
                    <div key={index} className="question">
                        <div className="question-header" onClick={() => toggleExpansion(index)}>
                            <div className='question-icon-container'><BsQuestionSquareFill className='question-icon' /></div> {q.question}
                        </div>
                        {expandedIndexes.includes(index) && (
                            <div className="answer">
                                {q.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default QuestionAnswer;