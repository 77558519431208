enum BlogPostTagEnum {
    SliceOfLife = "Быт в Аргентине",
    Sightseeing = "Что Посмотреть",
    Spanish = "Испанский",
    Weather = "Погода",
    Airlines = "Авиакомпании",
    Documents = "Документы",
    Flight = "Перелёт",
    ChildHealth = "Здоровье деток",
    AboutBirth = "О родах",
    Citizenship = "Гражданство",
    StoriesOfGivingBirth = "Истории родов",
    Finances = "Деньги"
}

export default BlogPostTagEnum;