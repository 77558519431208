import React from 'react';

const fullText_whatToBringToFlight: React.FC = () => {
    return (
        <div>
            <p>Перелет во время беременности - дело непростое. Особенно когда речь идет о длительном перелете через пол земного шара.</p>

            <p>Обязательно проконсультируйтесь с врачом перед полетом. Обязательным требованием многих авиакомпаний является наличие медицинской справки  на сроке от 27 недель. Но даже если вы летите раньше, я все равно рекомендую проконсультироваться с врачом.</p>

            <p>✔️ <strong>Лекарства</strong><br /><br />Если вам необходимо принимать лекарства, назначенные врачом, не забудьте положить необходимое количество в ручную кладь.</p>
            <p>✔️ <strong>Компрессионные чулки</strong><br /><br />Во время перелета они помогают предотвратить отечность. Попросите ваших близких помочь вам надеть чулки.</p>
            <p>✔️ <strong>Бандаж для поддержки живота</strong></p>
            <p>✔️ <strong>Таблетки нош-пы или дротаверина</strong></p>
            <p>✔️ <strong>Капли в нос от сухости</strong></p>
            <p>✔️ <strong>Не стоит забывать и о средствах личной гигиены</strong><br /><br />влажные салфетки, одноразовые прокладки и т.п.</p>
            <p>✔️ <strong>Не забудьте позаботиться о своем развлечении: возьмите в самолет наушники, журналы и книги.</strong></p>

            <p>О еде и напитках беспокоиться не нужно, предоставьте это авиакомпании.</p></div>
    )
};

export default fullText_whatToBringToFlight;