import React, { useEffect } from 'react';
import QuestionAnswer from './questionAnswerSection/QuestionAnswer';
import OurPackages from '../landingPageSections/packagesSection/OurPackages';

const FaqPage: React.FC = () => {

    //upon entering the page, scroll to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <section>
            <QuestionAnswer />
            <OurPackages />
        </section>
    )
}

export default FaqPage