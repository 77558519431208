import { Link, Route, Routes } from "react-router-dom";
import BlogArticlesArrayData from "../../blogPosts/blogArticlesArrayData";
import './RandomPostsStyles.css'
import BlogArticleFull from "./BlogArticleFull";
import ImageSpinnerWrapper from "../CustomImageWithSpinner";
import ReactGA from 'react-ga4';
import BlogArticleModel from "../../models/BlogArticleModel";
import ScrollAnimation from "../ScrollAnimation";

interface RandomPostsProps {
    isFilteringCurrentArticle: boolean;
    currentArticleId: string;
    arrayOfSpecificPostURLs?: string[];
    excludeArrayOfSpecificPostURLs?: string[]
}

const RandomPosts: React.FC<RandomPostsProps> = (props) => {



    const filterCurrentArticle = () => {
        return BlogArticlesArrayData.filter(article => article.blogArticleURL !== props.currentArticleId);
    };

    const getRandomPosts = (needToFilterCurrent: boolean) => {
        let filteredPosts;
        if (needToFilterCurrent) {
            filteredPosts = filterCurrentArticle();
        } else {
            if (props.arrayOfSpecificPostURLs?.length !== undefined) {
                filteredPosts = BlogArticlesArrayData.filter(article => props.arrayOfSpecificPostURLs?.includes(article.blogArticleURL));
            } else if (props.excludeArrayOfSpecificPostURLs?.length !== undefined) {
                filteredPosts = BlogArticlesArrayData.filter(article => !props.arrayOfSpecificPostURLs?.includes(article.blogArticleURL));
            }
            else {
                filteredPosts = BlogArticlesArrayData.filter(article => article);
            }

        }
        if (props.arrayOfSpecificPostURLs?.length !== undefined) {
            return filteredPosts;
        }
        else {
            const randomPosts = [];
            let numberOfPosts: number;
            numberOfPosts = props.arrayOfSpecificPostURLs?.length === undefined ? 3 : props.arrayOfSpecificPostURLs?.length;
            while (randomPosts.length < numberOfPosts && filteredPosts.length > 0) {
                const randomIndex = Math.floor(Math.random() * filteredPosts.length);
                randomPosts.push(filteredPosts[randomIndex]);
                filteredPosts.splice(randomIndex, 1);
            }
            return randomPosts;
        }
    };

    const randomPosts = getRandomPosts(props.isFilteringCurrentArticle);

    return (<div className="random-blog-articles-container" style={{
        marginTop: props.isFilteringCurrentArticle ? "" : "40px",
        marginBottom: props.isFilteringCurrentArticle ? "" : "40px"
    }}>
        {randomPosts.map(post => (
            <ScrollAnimation direction={'up'} customClassName='replace-slide-animation'>
                <Link
                    key={post.blogArticleURL}
                    to={`/blog/${post.blogArticleURL}/`} onClick={() => {
                        window.scrollTo(0, 0)
                        ReactGA.event({
                            category: 'User',
                            action: `${(props.isFilteringCurrentArticle ? "Из статьи" : "Из главной")} -> '${post.articleTitle}'`
                        })
                    }
                    }>
                    <div className="random-blog-article-container">
                        <div>
                            <ImageSpinnerWrapper>
                                <img src={require(`../../blogPosts/images/${post.blogImageURL}`)} className="random-blog-article-image" style={{ width: props.isFilteringCurrentArticle ? "" : "100%", maxWidth: props.isFilteringCurrentArticle ? "" : "350px" }}
                                    alt='random-article-image' />
                            </ImageSpinnerWrapper>
                        </div>
                        <div className="random-blog-article-heading">
                            <div className="random-blog-article-heading-text">{post.articleH1}</div>
                        </div>
                    </div>
                </Link>
            </ScrollAnimation>))
        }
        <Routes>
            <Route path="/blog/:id/*" element={<BlogArticleFull blogArticles={BlogArticlesArrayData} />} />
        </Routes>

    </div>)
}

export default RandomPosts;