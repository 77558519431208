import React from 'react';

const fullText_rentingInArgentina: React.FC = () => {
    return (
        <div>

            <div><p>В Аргентине существует три типа аренды жилья:</p>
                <p>- туристическая, temporario, на срок от суток;</p>
                <p>- временная, на 3 месяца;</p>
                <p>- постоянная, на 3 года.</p>
            </div>
            <br />
            <div>
                <p>Арендовать квартиру можно на распространенном сервисе <strong>Airbnb</strong>, если вы планируете прилететь на в аргентину на срок менее чем 3 месяца. Большинство квартир от собственников либо через риэлтора сдают на срок минимум 3 месяца.</p>
            </div>
            <br />
            <div>
                <p>Арендовать квартиру можно на следующих местных сервисах:</p>

                <p><a href="https://listado.mercadolibre.com.ar/departamentos-alquiler#D[A:departamentos%20alquiler]">www.mercadolibre.com.ar</a></p>
                <p><a href="https://www.argenprop.com/">www.argenprop.com</a></p>
                <p><a href="https://www.zonaprop.com.ar" >www.zonaprop.com.ar</a></p>
                <br />
                <p>При аренде жилья <strong>через агентство</strong> необходимо знать, что:</p>

                <p>- агенства берут комиссию в размере 20% от контракта</p>
                <p>- необходимо оставлять залог в размере ежемесячного платежа</p>
                <br />
                <p><strong>Важно:</strong> пожалуйста, внимательно читайте договор при его заключении. Используйте онлайн-переводчики с испанского, а в идеале приходите на заключение сделки совместно с переводчиком,  если не знаете испанский язык.</p>
            </div>
        </div >
    )
};

export default fullText_rentingInArgentina;