import React from 'react';
import ImageSpinnerWrapper from '../../components/CustomImageWithSpinner';

const fullText_cardMepRate: React.FC = () => {
    return (
        <div>
            <p>Как вы можете знать, в Аргентине много параллельных курсов доллара. Официальный курс не очень выгодный, и все ориентируются на так называемый <b>Blue курс доллара</b>. Что интересно, хоть он и не официальный, но бегущей строкой по телевизору показывают.</p>
            <p>Если у вас есть <b>международная карта</b>, то вы можете расплачиваться ей в Аргентине. Карты РФ не будут работать, а вот карты РБ (хоть и не все) работают в Аргентине.</p>
            <p>Некоторым моим клиентам удобно расплачиваться везде картой, чтобы часто не посещать обменники для наличных песо.</p>
            <p>Для удобства туристов, с декабря 2022 года <b>Visa</b> и <b>Mastercard</b> в Аргентине сделали расчет транзакций по курсу MEP для карт выпущенных за пределами Аргентины. Но есть нюанс.</p>
            <p>Давайте сначала посмотрим на <a target="_blank" rel="noopener noreferrer" href="https://www.ambito.com/contenidos/dolar.html">сайте ámbito актуальные курсы доллара для blue и для MEP</a>.</p>
            <p><b>Доллар blue</b> на 28 июня 13:20:<br />покупка <b>1325,00</b><br />продажа <b>1355,00</b></p>
            <ImageSpinnerWrapper>
                <img src={require('../images/assetsForPosts/card-mep-rate-in-argentina-blog-1.jpg')}
                    alt='ambito blue rate'
                    className='img-height-300' />
            </ImageSpinnerWrapper>
            <p><b>Доллар MEP</b> на 28 июня 13:35:<br />1337,82</p>
            <ImageSpinnerWrapper>
                <img src={require('../images/assetsForPosts/card-mep-rate-in-argentina-blog-2.jpg')}
                    alt='ambito MEP rate'
                    className='img-height-300' />
            </ImageSpinnerWrapper>
            <p>Расплатившись картой в магазине на 10.000 песо, их не посчитают как 1337,82 песо = 1 доллар. В этом-то и есть нюанс, который нужно прояснить. </p>
            <p>Будет использоваться MEP курс, но MEP курс от Visa и Mastercard и он отличается от того курса, который указан на сайте ámbito или других ресурсах.</p>
            <p>Идем в <a target="_blank" rel="noopener noreferrer" href="http://mastercard.com.ar/es-ar/consumidores/soporte/convertidor-moneda.html">калькулятор MEP от Mastercard</a>, который Mastercard любезно предоставляет туристам для подсчета MEP курса к разным валютам.</p>
            <p>Выбираем <br /><br /><b>From = ARS</b><br /><b>Amount = 1300</b><br /><b>To = USD</b> <br /><b>Bank fee = 0</b> (у каждого банка своя комиссия, иногда ее может и не быть - так как не знаем, то ставим 0).<br /><br />И внизу увидим <br /><b>1.300,00 ARS = 1,04 USD</b></p>
            <ImageSpinnerWrapper>
                <img src={require('../images/assetsForPosts/card-mep-rate-in-argentina-blog-3.jpg')}
                    alt='MC calculator MEP rate'
                    className='img-height-300' />
            </ImageSpinnerWrapper>
            <p>Подсчитаем за 1 USD:<br /><b>1300 / 1,04 = 1250 ARS</b> за 1 USD</p>
            <p>1250 меньше чем blue и MEP, но все же не такой низкий как официальный курс. В обменниках blue курс немного отличается в пользу обменника от blue, который на сайте - у каждого обменника будут актуальные курсы на данный момент времени.</p>
            <p>Но и 1250 не совсем финальная цифра. В зависимости от изменений курса, через пару дней могут либо прийти какие-то центы либо удержать центы от транзакции для корректировки. Также у банков может быть своя комиссия. Также, если слишком резкие скачки MEP, то нужно какое-то время, чтобы транзакции по картам считались по обновленному курсу (в калькуляторе Mastercard более менее будет видно, когда обновление произошло).</p>
            <p>В рамках эксперимента можете сделать какую-то маленькую покупку в песо, расплатиться картой и посмотреть сколько денег сняли с карты. Если с карты снимают не доллары, а какую-то местную валюту вашей карты, то посчитайте, сколько эта сумма будет в долларах.</p>
            <p>Сумма в песо / сумма в долларах = курс песо за 1 доллар по MEP, по которому банк провел транзакцию</p>
            <p>Например, <b>110.000 песо / 92 доллара = 1195,65 ARS за 1 USD по MEP</b>.</p>
            <p>И сравните это с тем, что покажет калькулятор от Mastercard. Возможна небольшая разница из-за комиссий банка.</p>
            <p>Картой таким образом можно расплачиваться в магазинах, аптеках, клиниках и, например, за анализы в лаборатории.</p>
        </div>
    );
};

export default fullText_cardMepRate;