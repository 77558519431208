import React from 'react';
import './OurClientsStyles.css';
import OurClientsCarousel from './OurClientsCarousel';

const OurClients: React.FC = () => {
    return (
        <section className='ourClientsSection' style={{ paddingBottom: '20px' }}>
            <h2 className='ourClientSectionHeading'>Наши Клиенты</h2>
            <OurClientsCarousel />
            {/* <div className='accompanyingCallToAction'>Узнать подробнее</div> */}
        </section>
    );
};

export default OurClients;