import React from 'react';

const fullText_airlineCompaniesForArgentina: React.FC = () => {
    return (
        <div>
            <p>Наиболее популярные авиакомпании, которые совершают перелеты в Аргентину</p>


            <div>
                <strong>Ethiopian Airlines</strong>
                <p>- справка по форме авиакомпании нужна с 28 недели</p>
                <p>- перелет разрешен до 34 недели (если он длится более 2 часов) и до 36 недели (если он длится менее 2 часов)</p>
                <p>- есть МЕДИФ, но на сайте нет информации о том, для какого именно срока нужна эта форма справки.</p> <p>Поэтому лучше уточнить на горячей линии или написав на электронную почту, потому что отвечают они всем по-разному. Вообще лучше иметь эту справку с собой</p>
            </div>
            <hr />
            <div>
                <strong>Qatar Airways</strong>
                <p>- до 28 недели включительно не требуется справка по форме авиакомпании, но рекомендуется её иметь для предотвращения задержек на контроле</p>
                <p>- с 29 (28 недель и 1 день) недели до 32 (то есть максимум 31 неделя и 7 дней) нужна справка по форме авиакомпании для одноплодной беременности и если беременность многоплодная, то еще дополнительно МЕДИФ (не старше 10 дней)</p>
                <p>- с 33 недели до конца 35 недели (34 недели и 7 дней) требуется МЕДИФ для одноплодной беременности, для многоплодной перелет с 33 недели запрещен</p>
                <p>- начиная с 36 недели и больше - перелет запрещен для всех беременных</p>
            </div>
            <hr />
            <div>
                <strong>Emirates</strong>
                <p>- справка нужна с 29 недели (информация: одноплодная/многоплодная беременность, ожидаемая дата родов, отсутствие противопоказаний и т.д.)</p>
                <p>- перелеты допустимы до 36 недели (до 32 если беременность многоплодная). Если вам все таки предстоит перелёт на указанном выше сроке, то нужно заполнить форму МЕДИФ</p>
            </div>
            <hr />
            <div>
                <strong>Turkish Airlines</strong>
                <p>- справка нужна с 28 недели беременности (не старше 7 дней от даты перелёта)</p>
                <p>- перелёт запрещен, начиная с 36 недели беременности (с 32 при многоплодной)</p>
                <p><strong>❗️❗️❗️ В последнее время участились случаи недопуска пассажиров, которые летят в Южную Америку, на борту данной авиакомпании. Рекомендую покупать невозвратный обратный билет в той же покупке, что и билет в Аргентину, или, по возможности, выбирать другую авиакомпанию.</strong></p>
            </div>
            <hr />
            <p>
                ⬆️ Это топ-4 самых популярных авиакомпаний, перелеты которых не требуют наличие визы для граждан РФ
            </p>

            <p>При подготовке к перелету, пожалуйста, ориентируйтесь только на информацию с официальных источников! Здесь лишь общие тезисы, требования могут меняться ❗️</p>

            <p>
                В Аргентину можно прилететь <strong>через Европу, если у вас есть соотвествующие документы</strong>:
            </p>
            <div>
                <strong>KLM Royal Dutch Airlines</strong>
                <p>- не рекомендуются перелеты после наступления 36 недели беременности и неделю после родов</p>
                <p>- рекомендуется иметь медицинское заключение с информацией о вашей беременности и держать его при себе</p>
            </div>
            <hr />
            <div>
                <strong>Air France</strong>
                <p>- официальных ограничений нет (не рекомендуется путешествовать с 37 недели и неделю после родов)</p>
                <p>- медицинское разрешение/справка не требуется (рекомендуется проконсультироваться с врачом)</p>
            </div>
            <hr />
            <div>
                <strong>British Airways</strong>
                <p>- перелеты разрешены до 36 недели (32 при многоплодной)</p>
                <p>- справка с информацией о сроке беременности, одноплодная/многоплодная, об отсутствии противопоказаний</p>
            </div>
            <hr />
            <div>
                <strong>Lufthansa</strong>
                <p>- перелеты разрешены до 36 недели</p>
                <p>- с 28 недели рекомендуется иметь справку</p>
            </div>
            <hr />
            <div>
                <strong>Iberia</strong>
                <p>- не рекомендуются перелеты после 36 недели (32 для многоплодной)</p>
                <p>- справка с 28 недели беременности</p>
            </div>
        </div>
    )
}

export default fullText_airlineCompaniesForArgentina;