import { useEffect } from 'react';

const UseOgTypeMetaTag = (ogTypeContent: string) => {
    useEffect(() => {
        // Function to update or create a meta tag
        const updateMetaTag = (property: string, content: string) => {
            let existingTag = document.head.querySelector(`meta[property="${property}"]`);
            if (existingTag) {
                existingTag.setAttribute('content', content);
            } else {
                const newTag = document.createElement('meta');
                newTag.setAttribute('property', property);
                newTag.setAttribute('content', content);
                document.head.appendChild(newTag);
            }
        };

        // Update the og:type meta tag
        updateMetaTag('og:type', ogTypeContent);
    }, [ogTypeContent]);
};

export default UseOgTypeMetaTag;