import React from 'react';
import navigationServices from '../../services/navigationServices';
import { Link } from 'react-router-dom';
import PackageOverview from '../../components/landingPageSections/packagesSection/PackageOverview';
import OurPackages from '../../components/landingPageSections/packagesSection/OurPackages';

const fullText_giveBirthInArgentinaHowMuchBudget: React.FC = () => {
    return (
        <div>
            <p>Рождение ребенка - волнительный и эмоциональный момент для каждой семьи, который требует тщательной подготовки. Подготовка к родам в стране на другом конце земного шара требует еще более тщательной подготовки и, конечно же, <i>планирования бюджета.</i> К слову, именно вопрос бюджета на роды в Аргентине является одним из самых популярных. Учесть надо многое: авиабилет, жилье, врач, госпиталь, обследования и анализы, траты на еду, проезд и развлечения - вот примерный список того, что нужно учитывать, планируя путешествие. Давайте посчитаем, сколько обойдется организация родов в Аргентине.</p>
            <p>Самая первая вещь, за которую придется заплатить ещё из дома - это, конечно же, <strong>авиабилеты</strong>. В Аргентине при прохождении паспортного контроля миграционная служба вправе потребовать у вас <i><u>обратные билеты</u></i> не позднее, чем через 90 дней с даты прилёта для граждан России и Беларуси (для граждан других государств безвизовый период может <u>отличаться</u>). Также обратные билеты может попросить и авиакомпания при регистрации на рейс. Так что далеко их прятать не стоит. Цена на билеты может <i>колебаться</i> и зависит от таких факторов, как <i>сезонность, авиакомпания, дата вылета, комфор</i>т (перелет в бизнес-классе или в эконом), и даже <i>город</i>, из которого вы вылетаете. В среднем авиабилеты из Москвы могут стоить от <strong>1500</strong> до <strong>5000</strong> долларов в обе стороны на двоих.</p>
            <p>Следующий важнейший пункт бюджета на роды в Аргентине - это выбор <strong>врача </strong>и<strong> госпиталя</strong>.</p>

            <p>Важный момент - имея опыт сопровождения родов как в бесплатных, так и в платных госпиталях, для максимального комфорта родов <b>настоятельно рекомендую</b> планировать в сторону платных госпиталей. Я не занимаюсь сопровождением родов в бесплатных (государственных) госпиталях.</p>

            <p>Самыми популярными госпиталями на роды остаются <i><strong><u>Swiss Medical</u></strong></i> и <i><strong><u>Sanatorio de Los Arcos</u></strong></i>. Естественные роды в этих клиниках обойдутся в <i><strong>2100$</strong></i>, а за кесарево сечение придется заплатить <i><strong>2700$</strong></i>. Есть и более бюджетные госпитали, которые стоят <i><strong>2000$</strong></i> и работают по системе “<i>все включено</i>” (анализы, УЗИ, обследования).</p>
            <p><strong>Врачи </strong>попросят от <i><strong>1600$</strong></i> до <i><strong>1700$</strong></i> за естественные роды и от <i><strong>1800$</strong></i> до <i><strong>2000$</strong></i> за кесарево сечение.</p>
            <p><i>Кесарево сечение</i>, как правило, стоит дороже, поскольку оно является хирургической операцией со всеми вытекающими последствиями в виде стерильной операционной, анестезиолога, специального оборудования.</p>
            <p>Кроме этого во время планирования бюджета нужно учесть такие вещи, как:</p>
            <ul>
                <li><strong>приемы у врачей</strong> - 20$ долларов за прием (в сумме около 200$)</li>
                <li><strong>анализы и узи</strong> 400$ долларов</li>
                <li>на <strong>жилье </strong>на три месяца советую отложить 4000$ (в среднем 800 в месяц за квартиру + комиссионные агенту недвижимости)</li>
                <li><strong>отель </strong>на первое время обойдется около 500$ за неделю на двоих</li>
            </ul>
            <p>Если вы планируете покупать <strong>коляску</strong>, <strong>кроватку </strong>и другие <strong>детские вещи</strong> в Аргентине, то для этих нужд следует выделить еще около <i><strong>1000$-1500$</strong></i>.</p>
            <p>Цена на <strong>услуги сопровождения</strong> может тоже быть разной. Некоторые предпочитают платить каждый раз переводчику по 20$ (крайне не рекомендую это делать), а другие купят <i>пакет сопровождения</i>, и будут спокойны, зная, что их точно сопроводят ко всем врачам и точно не бросят во время родов. Цены на пакеты сопровождения могут начинаться от <i>1500$</i>.</p>
            <p>Также необходимо помнить, что при выписке и оформлении свидетельства о рождении малыша необходимо присутствие <i><strong><u>официального</u></strong></i> переводчика. Его услуги обойдутся примерно в 25$-30$ долларов.</p>
            <hr />
            <p><i><strong>В среднем комфортные роды в Аргентине обходятся парам в 20-25 тысяч долларов.</strong></i></p>
            <hr />
            <p>Важно помнить, что планирование бюджета на роды в Аргентине - вещь <i><u>сугубо индивидуальная</u></i>. Так, к примеру, цены на новые коляски разнятся от 300$ до 3000$, кто-то предпочитает обедать в ресторанах, а кто-то любит домашнюю еду, можно отдать за пакет услуг 14000$, а можно сделать выбор <Link to="/"

                target="_blank" rel="noopener noreferrer">в пользу самого заботливого сопровождения и заплатить в разы меньше</Link>.</p>

            <OurPackages />

        </div>
    )
};
export default fullText_giveBirthInArgentinaHowMuchBudget;