import React from 'react';

const fullText_whatHappensAfterGivingBirth: React.FC = () => {
    return (
        <div>
            <p>Одна моя клиентка как-то у меня спросила: “<i>Вот лежу я после родов, положили мне моего только что рожденного ребенка на грудь, а в голове один единственный вопрос: <strong>а дальше что</strong></i>?”</p>
            <p>А дальше все достаточно просто.</p>
            <p>В Аргентине только что родившийся ребенок <strong>ни на секунду не должен оставаться один</strong>, без присмотра или мамы, или папы, поэтому присутствие папы на родах<i> строго обязательно</i>. Это делается для того, чтобы исключить риск подмены малыша и чтобы убедиться, что малышу не вколют ничего лишнего. Сразу после того, как малыш появился на свет, папа возьмет малыша и пойдет с ним на осмотр. Врачи измерят его <i>рост, вес,</i> проведут необходимые <i>обследования</i>, а также сделают малышу необходимые инъекции <strong>витамина К </strong>и прививку от <strong>Гепатита Б</strong>, закапают в глазки лекарство против <strong>конъюнктивита</strong>. Молодой отец должен будет заполнить документы на ребенка. Я, конечно же, буду рядом во время их заполнения, чтобы избежать ошибок в этом ответственном деле. Врачи будут учить папу, как вести себя рядом с малышом: что ему сказать, как его правильно держать и многое другое. В это время с мамой будут врачи и акушеры, которые будут приводить ее в порядок.</p>
            <p>В клинике, где вы пробудете несколько дней, вам будут помогать <strong>медицинские работники</strong>. Они покажут, как <i>пеленать малыша</i>, как правильно <i>ухаживать</i>, как его <i>кормить</i>. В Аргентине <i>абсолютное преимущество</i> отдаётся <strong>грудном вскармливанию</strong>, поэтому в каждой клинике есть консультанты по <i><strong>лактации</strong></i>. Если вам понадобилась помощь консультанта, нажмите на кнопку вызова медицинского персонала возле кровати и скажите: “<i><strong>Лактансия</strong></i>”. Специалист покажет вам, как правильно прикладывать малыша к груди, что нужно делать для профилактики мастита и других неприятностей. Если вам все же понадобилась покормить ребенка смесью, они научат ее дать ребенку так, чтобы малыш не отказался от груди.</p>
            <p>В клинике малышу также:</p>
            <p>✔️ Поставят две прививки: БЦЖ и первую дозу против Гепатита Б</p>
            <p>✔️ Проверят слух</p>
            <p>✔️ Регулярно будут проверять вес</p>
            <p>✔️ Регулярно будут следить за состоянием ребенка</p>
            <p>После выписки из госпиталя вас попросят один или несколько раз прийти на контроль к <i>неонатологу</i>, который повторно измерит рост, вес и окружность головы малыша, а также проверит глазки.</p>
            <p>Через месяц нужно будет сходить на приём к педиатру на <strong>контроль</strong>. Ему можно будет задать все вопросы, которые у вас накопились. Педиатр даст направление на <strong>прививки</strong>, выпишет необходимые <strong>витамины</strong>.</p>
            <p>В первый год жизни посещать педиатра необходимо каждый месяц.</p>

        </div>
    )
};
export default fullText_whatHappensAfterGivingBirth;