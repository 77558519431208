import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './CustomContactFormStyles.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Import CSS for react-phone-number-input
import ru from 'react-phone-number-input/locale/ru.json'
import YandexMetrikaEnums from '../../data/enums/YandexMetrikaEnum';

const CustomContactForm = () => {
  const [state, handleSubmit] = useForm("xovaanpv");
  const [phone, setPhone] = useState('');
  const [selectedOption, setSelectedOption] = useState('WhatsApp'); // Initial selected option
  const [yourQuestion, setYourQuestion] = useState('');

  const handlePhoneChange = (value: any) => {
    setPhone(value); // Update phone state directly with the value
  };

  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value); // Update selected option
  };

  const handleYourQuestionChange = (e: any) => {
    setYourQuestion(e.target.value);
  };

  useEffect(() => {
    if (state.succeeded) {
      const handleSuccess = () => {
        ym(YandexMetrikaEnums.id, 'reachGoal', 'order');
      };

      handleSuccess();
    }
  }, [state.succeeded]);

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h2>Оставьте заявку и получите консультацию</h2>
        <label htmlFor="name"><b>*</b>Ваше имя</label>
        <input id="name" type="text" name="name" required
          placeholder='Введите ваше имя'
          autoComplete='off'
          maxLength={30}
          disabled={state.succeeded ? true : false} />
        <ValidationError field="name" prefix="Name" errors={state.errors} />

        <label htmlFor="email"><b>*</b>Ваш еmail</label>
        <input id="email"
          type="email"
          name="email"
          required
          placeholder='Введите ваш email'
          autoComplete='off'
          maxLength={40}
          disabled={state.succeeded ? true : false} />
        <ValidationError field="email" prefix="Email" errors={state.errors} />

        <label htmlFor="phone"><b>*</b>Ваш телефон</label>
        <PhoneInput
          id="phone"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
          international
          defaultCountry="RU"
          required
          limitMaxLength
          labels={ru}
          disabled={state.succeeded ? true : false}
        />
        <ValidationError field="phone" prefix="Phone" errors={state.errors} />

        <label htmlFor="dropdown">Как с Вами связаться</label>
        <select id="dropdown" name="dropdown" value={selectedOption} onChange={handleOptionChange}
          disabled={state.succeeded ? true : false}>
          <option value="contact-via-whatsapp">WhatsApp</option>
          <option value="contact-via-telegram">Telegram</option>
          {/* Add more options as needed */}

        </select>

        <button type="submit"
          disabled={state.succeeded ? true : false}>
          Заказать консультацию
        </button>
        {state.succeeded && <p>Спасибо! Мы скоро с Вами свяжемся!</p>}
      </form>
    </div>
  );
};

export default CustomContactForm;