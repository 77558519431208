import React from 'react';

const fullText_clothesForWinterInBuenosAires: React.FC = () => {
    return (
        <div>
            <p>В Буэнос-Айресе осень в самом разгаре. Осенью температура воздуха достаточно комфортная, около 20-25 градусов. </p>

            <p> Зимы в Буэнос-Айресе тоже достаточно мягкие. Температура днем в среднем около 18 градусов, а ночью может опускаться до 4 градусов. </p>

            <p>Что я рекомендую взять с собой из одежды, если вы планируете посетить Аргентину и Буэнос-Айрес в зимний период:</p>


            <p>✔️ <strong>Непродуваемые куртки</strong><br /><br />В Буйэнос-Айресе часто бывает ветрено, особенно на открытых участках. Следует это учитывать при планировании прогулки.</p>

            <p>✔️ <strong>Демисезонная куртка или пальто</strong></p>

            <p>✔️ <strong>Теплый свитер</strong></p>

            <p>✔️ <strong>Теплая демисезонная обувь</strong></p>

            <p>✔️ <strong>Шапка, шарф и перчатки помогут защититься от ветра</strong></p>

            <p>✔️ <strong>Зонт</strong></p>

            <p>✔️ <strong>Теплая одежда для дома</strong><br /><br />Не во всех квартирах Буэнос-Айреса есть центральное отопление, поэтому зимой в квартирах может быть достаточно прохладно.</p>

        </div>
    )
};
export default fullText_clothesForWinterInBuenosAires;