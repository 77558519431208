import { NavLink } from 'react-router-dom';
import SiteMetaPropertyEnum from '../data/enums/SiteMetaPropertiesEnum';
import useTitleAndOgTitleMetaTag from '../components/UseTitleAndOgTitleMetaTag';

const NotFound = () => {

    useTitleAndOgTitleMetaTag(SiteMetaPropertyEnum.PageNotFoundSiteTitle, SiteMetaPropertyEnum.PageNotFoundSiteTitle);

    return (
        <div>
            <br />
            <h1>Страница не найдена</h1>
            <p>Вы пытаетесь найти или попасть на страницу, которой нет на этом сайте.</p>
            <p>Скорее всего, Вы искали:</p>
            <NavLink to="/" style={{ textDecoration: "none", fontWeight: "bold" }}>Роды с Ольгой · Самое заботливое сопровождение родов в Аргентине</NavLink><br />
            <br />
            <NavLink to="/blog/" style={{ textDecoration: "none", fontWeight: "bold" }}>Роды с Ольгой - Блог</NavLink>
            <p>Или воспользуйтесь навигацией в верхнем меню, чтобы попасть туда, куда нужно.</p>
        </div>
    );
}

export default NotFound;
