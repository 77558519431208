import React from 'react';
import './WorldMapTooltipStyles.css';

interface WorldMapTooltipProps {
    text: string;
    visible: boolean | null;
}

const WorldMapTooltip: React.FC<WorldMapTooltipProps> = ({ text, visible }) => {
    return (
        <div className={`tooltip ${visible ? 'visible' : ''}`}>
            {text}
        </div>
    );
};

export default WorldMapTooltip;