import React from 'react';
import { Link } from 'react-router-dom';
import navigationServices from '../../services/navigationServices';

const fullText_kidsDocumentsExplained: React.FC = () => {
    return (
        <div>
            <p>Одной из важнейших вещей, которую предстоит сделать после родов - это оформление документов на родившегося ребенка (<Link to="/blog/documents-to-bring/"

                target="_blank" rel="noopener noreferrer">для чего должны были быть подготовлены минимальные документы для родов</Link>). Процесс получения документов в Аргентине относительно прост, однако иногда может запутать молодых родителей. Итак, давайте разбираться.</p>
            <p>Самый первый документ, который получает малыш - это конечно же <strong>свидетельство о рождении</strong>. В день выписки из госпиталя родители подают документы для получения свидетельства о рождении. При подаче документов обязательно присутствие <strong>официального</strong> переводчика и <strong>обоих</strong> родителей! Время изготовления свидетельства о рождении разное в разных клиниках. Обычно это не занимает более 7 дней.</p>
            <p>На этапе получения свидетельства о рождении малышу присваивается номер DNI. Дальше уже можно подавать документы непосредственно на сам DNI.</p>
            <p>Для получения <strong>DNI</strong> малышу необходимы <i>паспорта родителей</i>, <i>свидетельство о рождении</i>, а также <i>фотография</i> самого малыша. Для того чтобы сфотографировать малыша вам нужна <i>белая пеленка</i> (подойдет и одноразовая). Фото можно сделать при подаче документов. Требования для фотографии на DNI не такие строгие, как для паспорта: ничего страшного, если малыш спит во то время, как его фотографируют: фотография может быть и с закрытыми глазами.</p>
            <p>Саму карточку DNI принесут вам на дом. Ждать карточку при обычном оформлении придется 3-4 недели. Отслеживать процесс изготовления карточки можно онлайн на сайте Renaper.</p>
            <p>Почти сразу после подачи документов на DNI можно подавать документы на <strong>паспорт</strong>. Сложности могут возникнуть на этапе <i>фотографирования</i> малыша. По стандартам глаза на фото должны быть открыты, а рот закрыт. Тут вам также понадобится <i>белая пеленка</i>. Ждать паспорта тоже нужно где-то 3-4 недели при обычном оформлении. Его также принесет курьер на дом.</p>
            <p>Во время ожидания почтальона, который должен будет вам доставить документы, вы должны помнить, что почтальон не будет подниматься к вам в квартиру. Когда почтальон позвонит вам в дверь, скажите ему “<i><strong>voy</strong></i>” (что значит “иду”) и выходите к нему со своим паспортом.</p>
            <p>Получив документы на ребенка, <Link to="/blog/parents-documents-explained/"

                target="_blank" rel="noopener noreferrer">родители могут начать заниматься своими документами в Аргентине.</Link></p>
        </div>
    )
};
export default fullText_kidsDocumentsExplained;