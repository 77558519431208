import React from 'react';

const fullText_firstVaccinationsForBabies: React.FC = () => {
    return (
        <div>
            <p>Чего только не найдешь в супермаркетах Аргентины! Вот, например, на бутылке обычного молока красуется календарь вакцинации детей.</p>
            <p>Вообще, прививки и вакцинация - одна из вечных тем, на которую можно спорить часами.</p>
            <p>В Аргентине все достаточно строго с прививками. Их обязательно делают всем деткам.</p>
            <p>Первые прививки вам поставят еще в роддоме. Сразу после рождения малышу вколют <strong>витамин К</strong> против детского кровотечения и первую дозу прививки против <strong>Гепатита В</strong>.</p>
            <p>На следующий день к вам придёт врач и поставит <strong>БЦЖ</strong> против туберкулеза. Врач должен будет объяснить вам, что с ней делать и как может изменяться место укола. Если врач этого не сделал, не стесняйтесь у него уточнить эту информацию.</p>
            <p>Следующие прививки нужно будет ставить только в два месяца. Для этого вам нужно будет направление от вашего педиатра.</p>
            <p>Прививки в два месяца можно будет поставить в<i> государственных клиниках</i>, в <i>частных медцентрах</i> и даже в <u>аптеках</u> (последний вариант крайне не рекомендую).&nbsp;</p>
            <p><strong><u>Частные медцентры</u></strong> - наиболее комфортный вариант, но цена прививок может отпугнуть. В <strong><u>государственных клиниках</u></strong> в свою очередь это можно сделать абсолютно бесплатно.</p>
            <p>В 2 месяца малыша ждут сразу <i><u>три дозы</u></i> прививок:</p>
            <p>✔️ Ротавирус&nbsp;</p>
            <p>✔️ Пятикомпонентная вакцина: дифтерия, гепатит Б, полиомиелит, столбняк, коклюш, гемофильная инфекция типа&nbsp;Б Hib</p>
            <p>✔️ Пневмококк</p>
            <p>Как правило, после этих прививок температура у малышей не поднимается. Но если все же это случилось, то ее можно сбить <strong>парацетамолом</strong> или другим жаропонижающим при наличии. Как правило, жаропонижающее выписывает педиатр на приеме вместе с направлениями на прививки. Обычно это детский парацетамол, который можно купить в любой аптеке.</p>
            <p>Можно ли отказаться от прививок? В теории, это возможно. Но я настоятельно <i><strong><u>не рекомендую</u></strong></i> это делать, поскольку это может стать причиной некоторых очень неприятных проблем.</p>
            <p>Кроме того, в Аргентине без прививок ребенка не примут:</p>
            <p>✔️в ясли</p>
            <p>✔️в детский сад</p>
            <p>✔️в школу</p>
            <p>Помимо обязательных прививок в осенний период вам также предложат пройти вакцинацию от гриппа. Если вашему малышу поставили прививку от гриппа, то советую вам запастись парацетамолом.</p>
            <p>Вопрос о прививках является сложным и многогранным. Важно помнить, что прививки - это важный инструмент в профилактике различных инфекций. Независимо от личного решения, главное - забота о здоровье и благополучии наших детей.</p>

        </div>
    )
};
export default fullText_firstVaccinationsForBabies;