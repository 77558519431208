import React from 'react';

const fullText_homeBirthWhatToKnow: React.FC = () => {
    return (
        <div>
            <p>В современном мире роды на дому становятся все более и более популярными. Активная реклама в фильмах и социальных сетях делают домашние роды все более привлекательными для девушек в наше время. Конечно, домашняя обстановка помогает женщине чувствовать себя спокойно и комфортно, что в свою очередь благотворно влияет на сам процесс родов. В процессе домашних родов сами родители могут принимать более активное участие в процессе родов: использование методов облегчения боли, различные позиции для того, чтобы прожить схватки и так далее. Как правило, домашние роды выбирают девушки, которые уже имеют одного или нескольких детей. Они имеют достаточно четкое представление о том, как проходят роды в домашней обстановке, что может произойти и какие препараты должны быть под рукой. Но есть и такие девушки, которые не имеют понятия о процессе родов, о рисках, связанных с ними, и рассчитывают на судьбу и высшие силы. Они представляют себе только идеальный сценарий родов.</p>
            <p>Важно помнить, что во время родов необходимо присутствие не только врача и акушерки для роженицы, но и неонатолога со всем необходимым оборудованием для малыша (например, для отсасывания околоплодных вод из легких ребенка, при необходимости). Если что-то пойдет не так, без такого оборудования ребенку будет очень сложно самостоятельно задышать, что может привести к очень нехорошим последствиям.</p>
            <p>Если вы решились на роды дома, необходимо заранее подготовиться и обезопасить себя и ребенка от неприятных ситуаций. Я вам советую договориться с неонатологом, чтобы он присутствовал на родах с необходимым оборудованием (мешок Амбу для помощи в раскрытии легких малыша, отсос для околоплодных вод), а также оборудование для послеродового обследования малыша. Самый лучший вариант - это договориться с бригадой скорой помощи, которая могла бы стоять рядом на улице во время родов на тот случай, если понадобиться экстренная помощь и/или госпитализация. Это позволит доставить маму и ребенка в клинику в кротчайшие сроки.&nbsp;</p>
            <p>На родовой процесс влияет ряд факторов: положение ребенка, длина пуповины, размеры ребенка и так далее. Даже если предыдущее роды прошли у вас идеально, это не означает, что следующие роды будут такими же. Все роды уникальны, все они разные. И ситуации в родах тоже бывают разными.</p>
            <p>Некоторые девушки делают выбор в пользу домашних родов, полагая, что это гарантирует максимально естественный процесс. Это совершенно необязательно. В Аргентине во многих госпиталях можно организоваться роды в уютной палате, которая будет напоминать домашнюю обстановку. Кроме того, на роды можно взять помощников: доулу, мужа, подругу. Можно включить любимую музыку и использовать аромамасла. Можно даже договориться, чтобы на родах не было никого, кроме акушерки. Но при этом медицинский персонал будет рядом, готовый оказать помощью в любую минуту.</p>
            <p>Говоря о домашних родах в Аргентине, нельзя обойти стороной и тему получения документов малыша после рождения. В отличие от родов в госпитале, процедура подачи документов на свидетельство о рождении ребенка, рожденного в домашних условиях, немного дольше и сложнее. Если рожавшие в госпитале имеют возможность это сделать сразу в госпитале в день выписки, то в случае домашних родов документы необходимо подавать в центральном загсе, куда необходимо брать турно заранее. Это влияет и на сроки выдачи свидетельства о рождении.</p>
            <p>Помните, что роды на дому могут закончиться удачно, а могут принести с собой печальные последствия, как для мамы, так и для малыша. Я призываю вас максимально обезопасить себя и вашего малыша.</p>
            <p>За все время работы в сфере организации родов в Аргентине, я побывала на более чем 100 родах. Не все они были на 100% идеальными, не все они заканчивались так, как хотелось бы матери в начале родов. Но абсолютно все они были успешными и закончились пополнением семьи на одного или даже на двух маленьких человечков сразу.</p>
        </div>
    )
}

export default fullText_homeBirthWhatToKnow;