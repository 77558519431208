import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./ReviewsFromClientsCarouselStyles.css"
import ReviewsModal from '../../ReviewsModal';
import ImageSpinnerWrapper from '../../CustomImageWithSpinner';

interface MyCarouselState {
    showModal: boolean;
    selectedImage: string | null;
}

class ReviewsFromClientsCarousel extends React.Component<{}, MyCarouselState> {
    state: MyCarouselState = {
        showModal: false,
        selectedImage: null,
    };

    handleImageClick = (imageUrl: string) => {
        this.setState({
            showModal: true,
            selectedImage: imageUrl,
        });
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            selectedImage: null,
        });
    };

    render() {
        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 3,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };

        const imagePaths = [
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_-1.jpg'),
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_1.jpg'),
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_2.jpg'),
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_3.jpg'),
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_4.jpg'),
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_5.jpg'),
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_6.jpg'),
            require('../../../assets/landingPage/reviewsFromClients/feedback_from_client_7.jpg'),
        ]

        return (
            <div style={{ marginBottom: "0px" }}>
                <Carousel
                    responsive={responsive}
                    arrows={true}
                    draggable={false}
                    swipeable={true}
                    showDots={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    keyBoardControl={false}
                    removeArrowOnDeviceType={[]}
                >
                    {imagePaths.map((imagePath, index) => (
                        <div key={index} onClick={() => this.handleImageClick(imagePath)}>
                            <ImageSpinnerWrapper>
                                <img src={imagePath} className='review-from-client-image'
                                    alt='review from client image' />
                            </ImageSpinnerWrapper>
                        </div>
                    ))}
                </Carousel>
                {this.state.showModal && (
                    <ReviewsModal imageUrl={this.state.selectedImage!} onClose={this.handleCloseModal} />
                )}
            </div>
        );
    }
}

export default ReviewsFromClientsCarousel;
