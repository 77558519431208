import React from 'react';
import './AboutOlgaStyles.css';
import { FaHandHoldingMedical } from "react-icons/fa";
import { MdOutlineMedicalInformation } from "react-icons/md";
import { FaBaby } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { FaHospitalUser } from "react-icons/fa";
import ImageSpinnerWrapper from '../../CustomImageWithSpinner';
import ScrollAnimation from '../../ScrollAnimation';

const AboutOlga: React.FC = () => (
    <section className='whyWithUsSection'>
        <h2 className='sectionHeadingText'>Обо мне</h2>
        <div className='whyWithUsContainer'>

            <ScrollAnimation direction={'down'} displayedThreshold={0.05} customClassName='replace-slide-animation'>
                <div className='whyWithUsRightAlign whyWithUsBlockWithPhoto'>

                    <div className='whyWithUsBlock about-Olga-border-left'>

                        <ScrollAnimation direction={'right'} customClassName='replace-slide-animation'>
                            <MdOutlineMedicalInformation className='whyWithUsBlockIcon' />
                        </ScrollAnimation>
                        <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'>
                            За моими плечами 30 лет медицинского стажа и более 100 успешных сопровождений родов разной сложности в Аргентине.
                        </ScrollAnimation>
                    </div>

                    <ScrollAnimation direction={'right'} customClassName='replace-slide-animation'>
                        <ImageSpinnerWrapper immediateLoad={true}>
                            <img src={require('../../../assets/landingPage/why_with_us_1.jpg')} className='imgWhyWithUs1' alt='Olga with flowers' />
                        </ImageSpinnerWrapper>
                    </ ScrollAnimation>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'down'} displayedThreshold={0.05} customClassName='replace-slide-animation'>
                <div className='whyWithUsLeftAlign whyWithUsBlockWithPhoto'>

                    <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'>
                        <ImageSpinnerWrapper immediateLoad={true}>
                            <img src={require('../../../assets/landingPage/why_with_us_2.jpg')} className='imgWhyWithUs1'
                                alt='Olga with doctor 1' />
                        </ImageSpinnerWrapper>
                    </ScrollAnimation>

                    <div className='whyWithUsBlock about-Olga-border-right'>
                        <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'><FaHandHoldingMedical className='whyWithUsBlockIcon' />
                        </ScrollAnimation>
                        <ScrollAnimation direction={'right'} customClassName='replace-slide-animation'>Медицинский опыт и знание сферы позволяют мне быть “на одной волне” с врачами. Мои клиенты чувствуют себя уверенно на протяжении всего процесса сопровождения, у них не возникает сомнений, что они проинформированы на 100%.</ScrollAnimation>

                    </div>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'down'} displayedThreshold={0.05} customClassName='replace-slide-animation'>
                <div className='whyWithUsRightAlign whyWithUsBlockWithPhoto'>

                    <div className='whyWithUsBlock about-Olga-border-left'>
                        <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'><FaUserDoctor className='whyWithUsBlockIcon' /></ ScrollAnimation>
                        <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'>Если Вам стало плохо на улице или на сдаче анализов, я знаю, что нужно делать. Со мной клиенты чувствуют себя в безопасности что бы ни случилось.
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation direction={'right'} customClassName='replace-slide-animation'>
                        <ImageSpinnerWrapper immediateLoad={true}>
                            <img src={require('../../../assets/landingPage/why_with_us_7.jpg')} className='imgWhyWithUs1' style={{ height: "250px" }}
                                alt='Olga in clinic' />
                        </ImageSpinnerWrapper>
                    </ScrollAnimation>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'down'} displayedThreshold={0.05} customClassName='replace-slide-animation'>
                <div className='whyWithUsLeftAlign whyWithUsBlockWithPhoto'>
                    <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'>
                        <ImageSpinnerWrapper immediateLoad={true}>
                            <img src={require('../../../assets/landingPage/why_with_us_5.jpg')} className='imgWhyWithUs1'
                                alt='Olga with doctor 2' />
                        </ImageSpinnerWrapper>
                    </ScrollAnimation>

                    <div className='whyWithUsBlock about-Olga-border-right'>
                        <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'><FaHospitalUser className='whyWithUsBlockIcon' />
                        </ScrollAnimation>
                        <ScrollAnimation direction={'right'} customClassName='replace-slide-animation'>В момент "X", я даю подробную инструкцию, как действовать, оставаясь с вами на связи.
                        </ScrollAnimation>
                    </div>
                </div>
            </ScrollAnimation>

            <ScrollAnimation direction={'down'} displayedThreshold={0.05} customClassName='replace-slide-animation'>
                <div className='whyWithUsRightAlign whyWithUsBlockWithPhoto'>



                    <div className='whyWithUsBlock about-Olga-border-left'>
                        <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'><FaBaby className='whyWithUsBlockIcon' />
                        </ScrollAnimation>
                        <ScrollAnimation direction={'right'} customClassName='replace-slide-animation'>Во время родов в течение всего процесса я буду с Вами до перевода в послеродовую палату.
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation direction={'left'} customClassName='replace-slide-animation'>
                        <ImageSpinnerWrapper immediateLoad={true}>
                            <img src={require('../../../assets/landingPage/why_with_us_6.jpg')} className='imgWhyWithUs1'
                                alt='Olga with baby' />
                        </ImageSpinnerWrapper>
                    </ScrollAnimation>

                </div>
            </ScrollAnimation>



        </div>

    </section >
);

export default AboutOlga;