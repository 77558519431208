import { Link } from "react-router-dom";
import navigationServices from "../../services/navigationServices";

const fullText_howMuchTimeBirthKidDocuments: React.FC = () => {
    return (
        <div>
            <p>Роды в Аргентине, как и другие роды заграницей, требуют значительной подготовки. <Link to="/blog/give-birth-in-argentina-how-much-budget/"
                target="_blank" rel="noopener noreferrer">Планирование бюджета</Link>, <Link to="/blog/airline-companies-to-fly-with/"
                    target="_blank" rel="noopener noreferrer">выбор авиабилетов</Link>, подбор жилья, <Link to="/blog/choosing-your-doctor/"
                        target="_blank" rel="noopener noreferrer">знакомства с врачами</Link> и экскурсии в клиники - это лишь малая часть того, с чем придется столкнуться будущим родителям Аргентинцев. Фактор времени играет одну значительную роль в подготовке родов в Аргентине.</p>
            <p>Итак, вы начинаете планировать роды в Аргентине. Сколько же времени надо тут жить, чтобы все успеть?</p>
            <p>Оптимальный срок для перелета - это 30-33 недели. Некоторые авиакомпании не разрешают перелеты на сроках больше 34-36 недель. Роды считаются срочными на сроке 37-42 недели. Получается, что в зависимости от срока, на котором вы прилетите и на котором родится ваш малыш, к моменту родов вы пробудете в Аргентине 2-3 месяца.</p>
            <p><Link to="/blog/what-happens-after-giving-birth/"
                target="_blank" rel="noopener noreferrer">После родов выписывают из клиники</Link> через 3 дня при естественных родах и через 4 дня при кесаревом сечении. В день выписки (если это не выходной) можно будет подать документы на изготовление свидетельства о рождении ребенка. Забрать его можно будет через 3-7 рабочих дней. Самое долгое время ожидание изготовления свидетельства о рождении в государственных бесплатных госпиталях. В частных госпиталях СОР сделают за 2-3 дня.</p>
            <p>В день, когда будет готово свидетельство о рождении, можно подавать документы на первое DNI малыша. В обычные сроки этот документ изготавливается 3-4 недели. Но на следующий день после подачи документов на первое DNI можно оформить его экспресс выпуск за несколько часов.</p>
            <p>Как только будет готово DNI можно подавать документы на изготовление загранпаспорта малыша. Сроки выпуска паспорта также варьируется в зависимости от срочности. Самый быстрый (и дорогой) паспорт можно изготовить в аэропорту за 2 часа. Также можно заказать срочный паспорт, который будет готов в течение трех дней. Его принесет почтальон к вам домой. Если говорить об изготовлении паспорта в нормальные сроки, то официальные сроки выпуска паспорта составляют месяц, но фактически паспорт нужно ждать 1,5 - 2 месяца, а иногда даже все три месяца.</p>
            <p>Еще один документ, о котором необходимо помнить, — это апостиль для свидетельства о рождении. Без него этот документ не будет действителен вне территории Аргентины. В Аргентине апостили бывают только электронные, которые приходят на электронную почту в течение 10 дней. В этот момент вы вполне можете уже не находится на территории Аргентины. Также я рекомендую сделать электронное свидетельство о рождении и апостиль на него. Электронное СОР будет готово в течение суток.</p>
            <p>Если рожать в частной клинике и делать документы в срочном порядке, то весь пакет документов малыша будет готов и будет у вас на руках уже через неделю после родов. В случае с бесплатными госпиталями, где срок изготовления СОР составляет неделю, все документы будут готовы в течение двух недель.</p>
            <p>Если вы никуда не спешите и можете прожить в Аргентине какое-то время, и вам не приходится заказывать изготовление документов для малыша в ускоренные сроки, то ждать документов необходимо будет несколько месяцев.</p>
            <p>Как правило, 90-дневного безвизового срока пребывания в Аргентине вполне хватает, для того чтобы родить малыша в Аргентине и получить на него все документы</p>
        </div>
    )
}


export default fullText_howMuchTimeBirthKidDocuments;