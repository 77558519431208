import React from 'react';

const fullText_supermarketsInBuenosAires: React.FC = () => {
    return (
        <div>
            <p>При планировании родов в Аргентине обычно возникают вопросы о врачах, госпиталях, обмене валют, билетах для перелета и многом другом. Но после перелета в другой конец света на первый план выходят бытовые вопросы: где лучше купить продукты, детские вещи, овощи, как добрать из одного места в другое, как пополнить сим-карту... Подобные вопросы мне задают каждый день, и мы все их обсуждаем на адаптационных встречах.</p>
            <p>Давайте сегодня поговорим о том, где лучше <strong>покупать продукты</strong> в Буэнос-Айресе.</p>
            <p>В Буэнос-Айресе есть несколько сетей супермаркетов разной категории:</p>
            <ul>
                <li>Dia</li>
                <li>Coto</li>
                <li>Carrefour</li>
                <li>Disco</li>
                <li>Jumbo</li>
                <li>Vea</li>
            </ul>
            <p>Последние три входят в группу Cencosud, крупнейшую и самую известную розничную компанию в Латинской Америке.</p>
            <p>Одним из самых <strong>бюджетных</strong> супермаркетов считается сеть <strong>DIA</strong>, когда как в <strong>Jumbo</strong> является одной из самых <strong>дорогих</strong> сетей, где можно найти привычные европейские товары.</p>
            <p>В супермаркетах Аргентины очень часто бывают <i>акции и скидки</i> на разные категории товаров, типа <strong>3 по цене 2</strong> или <strong>2 по цене 1</strong>, а также скидки <strong>на второй товар при покупке двух одинаковых товаров</strong>. Но очень важно читать ценники, поскольку часто скидки распространяются только на держателей дисконтных карт магазина, или акция распространяется на определенные даты (бывает так, что работники случайно или специально не убрали акционные ценники). &nbsp;Для оформления дисконтных карт необходимо иметь DNI. Некоторые оформляют дисконтные карты на номер загранпаспорта.</p>
            <p>В некоторых супермаркетах установлены кассы самообслуживания, где можно расплатиться картой. Важно, что при оплате картой на кассе самообслуживания необходимо ввести номер документа, на которую оформлена карта (для местных жителей - это DNI, для туристов - загранпаспорт).</p>
            <p>На кассе с кассиром <strong>при оплате картой</strong> в магазине, скорее всего <strong>кассир попросит ваш загранпаспорт</strong>. Это нужно для того, чтобы сверить имя на карте и в паспорте, и чтобы использовать ваш номер загранпаспорта как номер документа для проведения транзакации. Если у вас с собой никакого документа не будет, а только карта, то вполне могут и не обслужить. Если платить наличными, то загранпаспорт не просят. Немного может казаться необычным, но таковы порядки.</p>
            <p>Помимо супермаркетов, в Буэнос-Айресе часто можно встретить магазины фруктов и овощей. Они называются <strong>Verdulería</strong>. Овощи и фрукты выгоднее всего покупать именно там. Владельцы этих магазинчиков обычно закупают товары у местных фермеров и рынков, поэтому их продукты обычно свежие и намного вкуснее, чем в супермаркетах. <strong>Verdulería</strong> можно легко найти по соседству с вашим домом, что делает их удобным местом для ежедневных покупок.</p>
        </div >
    )
};

export default fullText_supermarketsInBuenosAires;