import React from 'react';
import StoriesOfGivingBirthPreText from '../../components/blog/StoriesOfGivingBirthPreText';

const fullText_birthStoriesPainInTheBackOfTheHead: React.FC = () => {
    return (
        <div>
            <StoriesOfGivingBirthPreText />
            <p>Нередко на поздних сроках беременности будущая мама может отмечать некоторые изменения в своем состоянии и/или поведении. Это неудивительно: малыш растет, ходить становится все труднее и труднее, матка сдавливает внутренние органы, отсюда может возникнуть тошнота, отеки, частое мочеиспускание. Очень часто девушки воспринимают эти симптомы, как нормальное положение дел, и просто ждут родов. Зачастую это действительно так и есть. Но есть симптомы, о которых молчать нельзя.</p>
            <p>Одна из рожениц пришла на УЗИ. Срок у нее был большой, ребенок уже доношенный, мы просто ждали родов. Во время процедуры она призналась мне, что у нее уже несколько дней очень сильно болит затылок и живот. И в целом ее самочувствие в последнее время было совсем плохое: ее мучили слабость, тошнота. В кабинете УЗИ нам разрешили измерить давление. Оно оказалось очень высоким, 180/120.</p>
            <p>Я связалась с врачом. Врач сказал, что нужно срочно ехать в больницу. Я вызвала такси (скорая тут едет очень долго), и мы с ней быстро поехали в клинику, а будущего папу отправили за вещами и документами. В клинике маме сделали экстренное кесарево сечение. Все прошло успешно, и через несколько дней счастливая мама с малышом отправились домой.</p>
            <p>Благодаря тому, что мама пожаловалась на плохое самочувствие на УЗИ, мы смогли оказать ей помощь и избежали эклампсии. Конечно, лучше до такого состояния не доводить, поэтому на последних сроках беременности следует обратить внимание на следующие симптомы:</p>
            <ul>
                <li>высокое давление (от 140/90 и выше)</li>
                <li>головная боль, особенно устойчивые и не проходящие после приема обычных анальгетиков (проконсультируйтесь с врачом, чтобы узнать, какие обезболивающие вам разрешены)</li>
                <li>изменения зрения: размытость, “мушки” в глазах, чувствительность к свету</li>
                <li>отеки</li>
                <li>РЕЗКИЙ набор веса, вызванный отеками</li>
                <li>боли в верхней части живота</li>
                <li>тошнота и рвота</li>
            </ul>
            <p>Если у вас появились эти симптомы, важно не замалчивать их, а сразу сказать об этом лечащему врачу. Мои клиенты всегда могут обратиться ко мне, и я свяжусь с врачом, который даст рекомендацию, что делать дальше.</p>
        </div>
    )
};

export default fullText_birthStoriesPainInTheBackOfTheHead;