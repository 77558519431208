import BlogPostTagEnum from "../data/enums/BlogPostsTagEnum";
import BlogArticleModel from "../models/BlogArticleModel";
import fullText_12ReasonsWhyArgentinaForGivingBirth from "./fullTextsForArticles/fullText_12ReasonsWhyArgentinaForGivingBirth";
import fullText_parentsDocumentsExplained from "./fullTextsForArticles/fullText_parentsDocumentsExplained";
import fullText_airlineCompaniesForArgentina from "./fullTextsForArticles/fullText_airlineCompaniesForArgentina";
import fullText_argentinaCitizenshipAfterGivingBirth from "./fullTextsForArticles/fullText_argentinaCitizenshipAfterGivingBirth";
import fullText_challengesInArgentinaToGiveBirth from "./fullTextsForArticles/fullText_challengesInArgentinaToGiveBirth";
import fullText_clothesForWinterInBuenosAires from "./fullTextsForArticles/fullText_clothesForWinterInBuenosAires";
import fullText_firstVaccinationsForBabies from "./fullTextsForArticles/fullText_firstVaccinationsForBabies";
import fullText_interestingFactAboutBabysLastName from "./fullTextsForArticles/fullText_interestingFactAboutBabysLastName";
import fullText_kidsDocumentsExplained from "./fullTextsForArticles/fullText_kidsDocumentsExplained";
import fullText_rentingInArgentina from "./fullTextsForArticles/fullText_rentingInArgentina";
import fullText_whatDocumentsToBring from "./fullTextsForArticles/fullText_whatDocumentsToBring";
import fullText_whatToBringToFlight from "./fullTextsForArticles/fullText_whatToBringToFlight";
import fullText_giveBirthInArgentinaHowMuchBudget from "./fullTextsForArticles/fullText_giveBirthInArgentinaHowMuchBudget";
import fullText_howAccompanyingWorks from "./fullTextsForArticles/fullText_howAccompanyingWorks";
import fullText_whatHappensAfterGivingBirth from "./fullTextsForArticles/fullText_whatHappensAfterGivingBirth";
import fullText_birthStoriesPostpartumPreeclampsia from "./fullTextsForArticles/fullText_birthStoriesPostpartumPreeclampsia";
import fullText_birthStoriesPostpartumBleeding from "./fullTextsForArticles/fullText_birthStoriesPostpartumBleeding";
import fullText_birthStoriesNightlyBronchospasm from "./fullTextsForArticles/fullText_birthStoriesNightlyBronchospasm";
import fullText_birthStoriesPainInTheBackOfTheHead from "./fullTextsForArticles/fullText_birthStoriesPainInTheBackOfTheHead";
import fullText_trustingDoctors from "./fullTextsForArticles/fullText_trustingDoctors";
import fullText_cardMepRate from "./fullTextsForArticles/fullText_cardMepRate";
import fullText_supermarketsInBuenosAires from "./fullTextsForArticles/fullText_supermarketsInBuenosAires";
import fullText_cheaperLifeArgentinaBlueOfficial from "./fullTextsForArticles/fullText_cheaperLifeArgentinaBlueOfficial";
import fullText_differencesBetweenDoulaAndMidwife from "./fullTextsForArticles/fullText_differencesBetweenDoulaAndMidwife";
import fullText_comparingPrivateAndPublicHospitals from "./fullTextsForArticles/fullText_comparingPrivateAndPublicHospitals";
import fullText_homeBirthWhatToKnow from "./fullTextsForArticles/fullText_homeBirthWhatToKnow";
import fullText_partnerBirth from "./fullTextsForArticles/fullText_partnerBirth";
import fullText_choosingYourDoctor from "./fullTextsForArticles/fullText_choosingYourDoctor";
import fullText_optimizeYourBudget from "./fullTextsForArticles/fullText_optimizeYourBudget";
import fullText_howMuchTimeBirthKidDocuments from "./fullTextsForArticles/fullText_howMuchTimeBirthKidDocuments";
import fullText_documentsDeliveryPostalService from "./fullTextsForArticles/fullText_documentsDeliveryPostalService";

const BlogArticlesArrayData: BlogArticleModel[] = [
    {
        blogArticleURL: "12-reasons-why-argentina-for-giving-birth",
        datePublished: "27/05/2024",
        articleTitle: "Роды в Аргентине 2024: 12 причин родить здесь",
        articleH1: "12 причин родить в Аргентине в 2024",
        articleSummary: "Каждая будущая мама хочет, чтобы роды прошли идеально. Поэтому очень тщательно подходит к выбору врача, клиники и даже страны, где появится на свет ее малыш",
        articleComponent: fullText_12ReasonsWhyArgentinaForGivingBirth,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "12-reasons-why-argentina-for-giving-birth.jpg"
    },
    {
        blogArticleURL: "argentina-citizenship-after-giving-birth",
        datePublished: "01/06/2024",
        articleTitle: "Получить гражданство Аргентины родителям после родов",
        articleH1: "Гражданство Аргентины после родов",
        articleSummary: "Аргентина - страна с самым быстрым получением гражданства особенно после родов в Аргентине. Рассмотрим верно ли это утверждение и что вообще можно ожидать",
        articleComponent: fullText_argentinaCitizenshipAfterGivingBirth,
        tags: [BlogPostTagEnum.Citizenship],
        blogImageURL: "argentina-citizenship-after-giving-birth.jpg"
    },
    {
        blogArticleURL: "give-birth-in-argentina-how-much-budget",
        datePublished: "02/06/2024",
        articleTitle: "Роды в Аргентине: стоимость и бюджет 2024",
        articleH1: "Роды в Аргентине 2024 - цена стоимость бюджет",
        articleSummary: "Стоимость родов в Аргентине - один из самых популярных вопросов. Посчитаем, во сколько обойдется организация родов в Аргентине и какой планировать бюджет",
        articleComponent: fullText_giveBirthInArgentinaHowMuchBudget,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "give-birth-in-argentina-how-much-budget.jpg"
    },
    {
        blogArticleURL: "comparing-private-and-public-hospitals",
        datePublished: "11/07/2024",
        articleTitle: "Бесплатные и платные роды в Аргентине 2024 - сравнение",
        articleH1: "Роды в Аргентине 2024 - бесплатные и платные сравнение",
        articleSummary: "Рассмотрим разницу между родами в бесплатном и платном госпитале в Аргентине на этапах ведения беременности и собственно самих родов",
        articleComponent: fullText_comparingPrivateAndPublicHospitals,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "comparing-private-and-public-hospitals.jpg"
    },
    {
        blogArticleURL: "how-accompanying-works",
        datePublished: "05/06/2024",
        articleTitle: "Роды в Аргентине 2024: как работает сопровождение родов",
        articleH1: "Сопровождение родов в Аргентине 2024 - как это работает",
        articleSummary: "Подробно рассказываем, как выглядит сопровождение родов в Аргентине и что можно и нужно ожидать от сотрудничества",
        articleComponent: fullText_howAccompanyingWorks,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "how-accompanying-works.jpg"
    },
    {
        blogArticleURL: "cheaper-life-argentina-blue-official",
        datePublished: "03/07/2024",
        articleTitle: "Курсы доллара - Как родить дешевле в Аргентине?",
        articleH1: "Родить в Аргентине дешевле? Blue и официальный доллары",
        articleSummary: "Если большой разрыв между офицальным и blue курсами доллара, то оплата госпиталя и быт в Аргентине будут дешевле",
        articleComponent: fullText_cheaperLifeArgentinaBlueOfficial,
        tags: [BlogPostTagEnum.Finances],
        blogImageURL: "cheaper-life-argentina-blue-official.jpg"
    },
    {
        blogArticleURL: "challenges-in-argentina-to-give-birth",
        datePublished: "28/05/2024",
        articleTitle: "Роды в Аргентине 2024: сложности и минусы",
        articleH1: "Минусы и сложности родов в Аргентине в 2024",
        articleSummary: "О плюсах говорить просто, но не все говорят о сложностях с родами в Аргентине. Рассмотрим, почему все может быть не так просто, как кажется на первый взгляд",
        articleComponent: fullText_challengesInArgentinaToGiveBirth,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "challenges-in-argentina-to-give-birth.jpg"
    },
    {
        blogArticleURL: "documents-to-bring",
        datePublished: "10/05/2024",
        articleTitle: "Роды в Аргентине 2024: Документы для родов",
        articleH1: "Документы для родов в Аргентине 2024",
        articleSummary: "Посмотрим на минимальный набор документов, которые необходимо подготовить и взять с собой, если решили рожать в Аргентине",
        articleComponent: fullText_whatDocumentsToBring,
        tags: [BlogPostTagEnum.Documents],
        blogImageURL: "what-documents-to-bring.jpg"
    },

    {
        blogArticleURL: "how-much-time-birth-kid-documents",
        datePublished: "16/09/2024",
        articleTitle: "Сколько времени занимают роды и документы на ребенка в Аргентине 2024",
        articleH1: "Сколько времени на роды и документы на ребенка в Аргентине 2024",
        articleSummary: "Вы начинаете планировать роды в Аргентине. Сколько же времени надо тут жить, чтобы все успеть?",
        articleComponent: fullText_howMuchTimeBirthKidDocuments,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "how-much-time-birth-kid-documents.jpg"
    },

    {
        blogArticleURL: "home-birth-what-to-know",
        datePublished: "16/07/2024",
        articleTitle: "Домашние роды в Аргентине: особенности и риски",
        articleH1: "Особенности и риски домашних родов в Аргентине",
        articleSummary: "Роды дома в Аргентине имеют место быть. Рассмотрим, что нужно знать и учитывать, выбирая такие роды и какие есть риски и особенности",
        articleComponent: fullText_homeBirthWhatToKnow,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "home-birth-what-to-know.jpg"
    },
    {
        blogArticleURL: "differences-between-doula-and-midwife",
        datePublished: "05/07/2024",
        articleTitle: "Роды в Аргентине: Доула и акушерка различия",
        articleH1: "Разница между доулой и акушеркой на родах в Аргентине",
        articleSummary: "Женщине в родах могут помочь акушерка и доула. Какую роль в процессе родов играют эти два человека? Давайте подробно разберемся",
        articleComponent: fullText_differencesBetweenDoulaAndMidwife,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "differences-between-doula-and-midwife.jpg"
    },
    {
        blogArticleURL: "trusting-doctors",
        datePublished: "22/06/2024",
        articleTitle: "Роды в Аргентине 2024: доверие к врачам",
        articleH1: "Доверие к врачам в Аргентине и протоколы ведения родов",
        articleSummary: "Медицинские протоколы в Аргентине отличаются. Но врачи руководствуются именно ими и заинтересованы в благополучном исходе родов",
        articleComponent: fullText_trustingDoctors,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "trusting-doctors.jpg"
    },

    {
        blogArticleURL: "partner-birth",
        datePublished: "20/07/2024",
        articleTitle: "Партнерские роды в Аргентине. Особенности",
        articleH1: "Особенности партнерских родов в Аргентине",
        articleSummary: "Аргентина — страна партнерских родов. Присутствие партнера на родах в Аргентине считается обязательным. Рассмотрим особенности",
        articleComponent: fullText_partnerBirth,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "partner-birth.jpg"
    },

    {
        blogArticleURL: "optimize-your-budget",
        datePublished: "14/08/2024",
        articleTitle: "Оптимизация стоимости и бюджета для родов в Аргентине",
        articleH1: "Как оптимизировать бюджет на роды в Аргентине?",
        articleSummary: "В каких аспектах можно сэкономить и уменшить конечный счет на роды в Аргентине? Рассмотрим разные стороны вопроса. На здоровье экономить не нужно.",
        articleComponent: fullText_optimizeYourBudget,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "optimize-your-budget.jpg"
    },

    {
        blogArticleURL: "choosing-your-doctor",
        datePublished: "07/08/2024",
        articleTitle: "Выбор врача для родов в Аргентине",
        articleH1: "10 вопросов для выбора врача для родов в Аргентине",
        articleSummary: "Выбор врача - одна из первых задач, которую вам предстоит сделать после приезда в Аргентину. К этому выбору следует подойти очень ответственно.",
        articleComponent: fullText_choosingYourDoctor,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "choosing-your-doctor.jpg"
    },

    {
        blogArticleURL: "birth-stories-postpartum-preeclampsia",
        datePublished: "13/06/2024",
        articleTitle: "История родов в Аргентине: боль в животе после родов",
        articleH1: "После родов в Аргентине боль в животе - История",
        articleSummary: "У девушки ожидалась двойня. Беременность проходила нормально. Пока однажды в 3 часа ночи я не получила от нее звонок: открылось обильное кровотечение",
        articleComponent: fullText_birthStoriesPostpartumPreeclampsia,
        tags: [BlogPostTagEnum.StoriesOfGivingBirth],
        blogImageURL: "birth-stories-postpartum-preeclampsia.jpg"
    },
    {
        blogArticleURL: "what-happens-after-giving-birth",
        datePublished: "12/06/2024",
        articleTitle: "Роды в Аргентине: в госпитале после родов",
        articleH1: "Что происходит в госпитале после родов в Аргентине",
        articleSummary: "Вот лежу я после родов, положили мне моего только что рожденного ребенка на грудь, а в голове один единственный вопрос: а дальше что?",
        articleComponent: fullText_whatHappensAfterGivingBirth,
        tags: [BlogPostTagEnum.AboutBirth],
        blogImageURL: "what-happens-after-giving-birth.jpg"
    },
    {
        blogArticleURL: "kids-documents-explained",
        datePublished: "30/05/2024",
        articleTitle: "Роды в Аргентине: документы на ребенка после родов",
        articleH1: "Документы на ребенка после родов в Аргентине",
        articleSummary: "Документы в Аргентине могут запутать молодых родителей. Итак, давайте разбираться, какие документы будут у деток после родов",
        articleComponent: fullText_kidsDocumentsExplained,
        tags: [BlogPostTagEnum.Documents],
        blogImageURL: "kids-documents-explained.jpg"
    },
    {
        blogArticleURL: "birth-stories-pain-in-the-back-of-the-head",
        datePublished: "19/06/2024",
        articleTitle: "История родов в Аргентине: сильная боль в затылке до родов",
        articleH1: "До родов в Аргентине сильная боль в затылке - История",
        articleSummary: "Во время УЗИ (срок был большой) девушка призналась мне, что у нее уже несколько дней очень сильно болит затылок и живот. В кабинете УЗИ нам измерили давление",
        articleComponent: fullText_birthStoriesPainInTheBackOfTheHead,
        tags: [BlogPostTagEnum.StoriesOfGivingBirth],
        blogImageURL: "birth-stories-pain-in-the-back-of-the-head.jpg"
    },
    {
        blogArticleURL: "parents-documents-explained",
        datePublished: "02/06/2024",
        articleTitle: "Роды в Аргентине: Документы на родителей после родов",
        articleH1: "Документы на родителей после родов в Аргентине. Легализация",
        articleSummary: "Когда у малыша будут готовы его местные документы, родители могут запускать процесс воссоединения с семьей для получения перманентной резиденции в Аргентине",
        articleComponent: fullText_parentsDocumentsExplained,
        tags: [BlogPostTagEnum.Documents],
        blogImageURL: "parents-documents-explained.jpg"
    },
    {
        blogArticleURL: "card-mep-rate-in-argentina",
        datePublished: "28/06/2024",
        articleTitle: "Курс доллара MEP - оплата картами в Аргентине 2024",
        articleH1: "Как работает курс MEP при оплате картами в Аргентине 2024",
        articleSummary: "Если у вас есть международная карта, то вы можете расплачиваться ей в Аргентине по неплохому курсу доллара",
        articleComponent: fullText_cardMepRate,
        tags: [BlogPostTagEnum.Finances],
        blogImageURL: "card-mep-rate-in-argentina.jpg"
    },
    {
        blogArticleURL: "airline-companies-to-fly-with",
        datePublished: "11/05/2024",
        articleTitle: "Роды в Аргентине 2024: Авиакомпании для перелета",
        articleH1: "Какими авиакомпаниями лететь в Аргентину на роды в 2024",
        articleSummary: "Список авиакомпаний, которые совершают перелеты в Аргентину, и требования каждой компании для допуска на борт самолета",
        articleComponent: fullText_airlineCompaniesForArgentina,
        tags: [BlogPostTagEnum.Airlines],
        blogImageURL: "airline-companies-to-fly-with.jpg"
    },

    {
        blogArticleURL: "documents-delivery-postal-service",
        datePublished: "18/09/2024",
        articleTitle: "Почта и доставка готовых документов на дом в Аргентине. Особенности",
        articleH1: "Готовые документы и почта Аргентины - особенности",
        articleSummary: "Готовые документы DNI и загранпаспорт доставляют почтальоны на дом. Есть свои особенности, которые нужно знать, чтобы не пропустить почтальона",
        articleComponent: fullText_documentsDeliveryPostalService,
        tags: [BlogPostTagEnum.Documents],
        blogImageURL: "documents-delivery-postal-service.jpg"
    },

    {
        blogArticleURL: "birth-stories-nightly-bronchospasm",
        datePublished: "18/06/2024",
        articleTitle: "История родов в Аргентине: странное дыхание малыша",
        articleH1: "После родов в Аргентине странное дыхание у малыша - История",
        articleSummary: "Через 1,5 месяца после родов малыш все время спал, мама не могла его добудиться, он странно дышал, губы и носогубный треугольник отличались по цвету",
        articleComponent: fullText_birthStoriesNightlyBronchospasm,
        tags: [BlogPostTagEnum.StoriesOfGivingBirth],
        blogImageURL: "birth-stories-nightly-bronchospasm.jpg"
    },
    {
        blogArticleURL: "what-to-bring-to-flight",
        datePublished: "15/05/2024",
        articleTitle: "Что взять с собой в самолет на рейс в Аргентину",
        articleH1: "Самолет в Аргентину - что взять с собой беременным на рейс",
        articleSummary: "Перелет во время беременности - дело непростое. Особенно через пол земного шара. Мои рекомендации для длительного перелета",
        articleComponent: fullText_whatToBringToFlight,
        tags: [BlogPostTagEnum.Flight],
        blogImageURL: "what-to-bring-to-flight.jpg"
    },
    {
        blogArticleURL: "types-of-rent",
        datePublished: "13/05/2024",
        articleTitle: "Аренда жилья в Аргентине",
        articleH1: "Кратко об аренде жилья в Аргентине",
        articleSummary: "В Аргентине существует три типа аренды жилья. Подробнее об особенностях и нюансах каждого типа читайте в статье",
        articleComponent: fullText_rentingInArgentina,
        tags: [BlogPostTagEnum.SliceOfLife],
        blogImageURL: "types-of-rent.jpg"
    },
    {
        blogArticleURL: "last-name-of-the-baby-in-hospital",
        datePublished: "22/05/2024",
        articleTitle: "Роды в Аргентине: Фамилия малыша в госпитале",
        articleH1: "Фамилия малыша в госпитале в Аргентине",
        articleSummary: "В славянских языках фамилия у мужа и жены отличаются окончаниями. Как же запишут фамилию малыша в Аргентине?",
        articleComponent: fullText_interestingFactAboutBabysLastName,
        tags: [BlogPostTagEnum.Documents],
        blogImageURL: "last-name-of-the-baby-in-hospital.jpg"
    },
    {
        blogArticleURL: "birth-stories-postpartum-bleeding",
        datePublished: "16/06/2024",
        articleTitle: "История родов в Аргентине: обильное кровотечение после родов",
        articleH1: "После родов в Аргентине обильное кровотечение - История",
        articleSummary: "Через 2-3 недели после родов мама вдруг мне позвонила посреди ночи. У неё началось очень сильное кровотечение, которое никак не могло остановиться",
        articleComponent: fullText_birthStoriesPostpartumBleeding,
        tags: [BlogPostTagEnum.StoriesOfGivingBirth],
        blogImageURL: "birth-stories-postpartum-bleeding.jpg"
    },
    {
        blogArticleURL: "first-vaccinations-for-babies",
        datePublished: "27/05/2024",
        articleTitle: "Роды в Аргентине: привики для аргентинских деток",
        articleH1: "О привиках для аргентинских деток",
        articleSummary: "На бутылке молока в супермаркете красуется календарь вакцинации детей. В Аргентине все достаточно строго с прививками. Их обязательно делают всем деткам",
        articleComponent: fullText_firstVaccinationsForBabies,
        tags: [BlogPostTagEnum.ChildHealth],
        blogImageURL: "first-vaccinations-for-babies.jpg"
    },
    {
        blogArticleURL: "where-to-buy-food-in-ba",
        datePublished: "30/06/2024",
        articleTitle: "Где покупать продукты в Аргентине Буенос-Айрес",
        articleH1: "Покупка еды в супермаркетах Аргентины БА",
        articleSummary: "Где можно покупать еду в Буенос-Айресе, какие есть особенности, и на что нужно обратить внимание",
        articleComponent: fullText_supermarketsInBuenosAires,
        tags: [BlogPostTagEnum.SliceOfLife],
        blogImageURL: "where-to-buy-food-in-ba.jpg"
    },
    {
        blogArticleURL: "clothes-for-winter-in-buenos-aires",
        datePublished: "20/05/2024",
        articleTitle: "Роды в Аргентине: зимняя одежда для Буенос-Айреса",
        articleH1: "Одежда для зимы в Буенос-Айресе",
        articleSummary: "Что я рекомендую взять с собой из теплой одежды и вещей, если Вы планируете посетить Буэнос-Айрес в зимний период",
        articleComponent: fullText_clothesForWinterInBuenosAires,
        tags: [BlogPostTagEnum.Weather],
        blogImageURL: "clothes-for-winter-in-buenos-aires.jpg"
    },

]

export default BlogArticlesArrayData;