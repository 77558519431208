import React from 'react';
import './PackageInformationStandardStyles.css'
import { MdSupportAgent } from "react-icons/md";
import { IoAirplaneOutline } from "react-icons/io5";
import { FaRegHospital } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { TbEmergencyBed } from "react-icons/tb";
import { MdBabyChangingStation } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaPlus, FaUserDoctor } from "react-icons/fa6";

const PackageInformationAlreadyHere: React.FC = () => {
    return (
        <div className='expandedStandardPackage' style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            backgroundColor: "inherit"
        }}>
            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><MdSupportAgent className='standardPackageHeadingIcon' /> Информационная поддержка</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Мы с командой будем консультировать Вас по всем интересующим вопросам</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><FaUserDoctor className='standardPackageHeadingIcon' />Знакомство с врачом и акушеркой</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><FaRegHospital className='standardPackageHeadingIcon' />Организация экскурсий в клиники</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Посмотрим клиники, которые вас заинтересуют</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><IoPeople className='standardPackageHeadingIcon' />Сопровождение переводчика по медицинским вопросам до и после родов</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Сопровождение к врачам и на анализы и два визита после родов (один для мамы и один к неонатологу)</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><TbEmergencyBed className='standardPackageHeadingIcon' />Сопровождение на родах</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Буду с Вами от самого начала родов и до перевода в послеродовую палату</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><MdBabyChangingStation className='standardPackageHeadingIcon' />Выписка из роддома</div>
            <div style={{ color: "inherit" }} className='package-item-text'>В день выписки встретим Вас с цветами, поможем подать документы на свидетельство о рождении</div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><FaRegAddressCard className='standardPackageHeadingIcon' />Получение аргентинских документов на ребенка</div>
            <div style={{ color: "inherit" }} className='package-item-text'>Оформим Свидетельство о рождении, DNI и паспорт для малыша. <strong>НЕ включает срочное оформление документов</strong></div>

            <div className='standardPackageHeading' style={{ color: "var(--verde)" }}><IoDocumentTextOutline className='standardPackageHeadingIcon' />Апостилирование свидетельства о рождении
            </div>

        </div>
    );
};

export default PackageInformationAlreadyHere;