import React from 'react';
import { Link } from 'react-router-dom';
import navigationServices from '../../services/navigationServices';

const fullText_parentsDocumentsExplained: React.FC = () => {
    return (
        <div>
            <p>После того как <Link to="/blog/kids-documents-explained/"

                target="_blank" rel="noopener noreferrer">у малыша будет готово его DNI</Link>, родители могут могут запускать процесс воссоединения с семьей для получения перманентной резиденции в Аргентине.</p>
            <p>Список документов для подачи на DNI включает в себя:</p>
            <p>✔️Загранпаспорт</p>
            <p>✔️Свидетельство о рождении</p>
            <p>✔️Справка о несудимости из страны гражданства с апостилем</p>
            <p>✔️Свидетельство о заключении брака</p>
            <p>✔️Свидетельство о рождении ребенка</p>
            <p>✔️DNI ребенка</p>
            <p>✔️Справка Domicilio (о месте жительства в Аргентине). Ее необходимо брать в отделении полиции вашего района</p>
            <p>Все документы, выданные не в Аргентине должны быть апостилированы в стране выдаче. Загранпаспорт не апостилируется.</p>
            <p>Переведены апостилированные документы должны быть на территории Аргентины сертифицированным переводчиком из коллегии переводчиков (эти же переводы документов в дальнейшем могут быть использваны в пакете документов для дела о гражданстве).</p>
            <p>Миграционная служба может рассматривать ваши заявления относительно быстро, а может и медленно, а может и запросить уточняющие документы. На данный момент (май 2024) <b>логично закладывать 4-6 месяцев и больше на рассмотрение и одобрение перманентной резиденции</b>.</p>
            <p>Что дает DNI?</p>
            <p>Нужно понимать, что DNI для иностранцев - это не гражданство Аргентины. Этот документ не дает вам право посещать больше 170 стран мира по без визы в отличие от паспорта. Однако, DNI дает вам ряд преимуществ для жизни в Аргентине таких как:</p>
            <p>✔️ легальный статус</p>
            <p>✔️ открытие счета в банке</p>
            <p>✔️ оформление медицинской страховки</p>
            <p>✔️ путешествие по странам Латинской Америки, входящих в состав MERCOSUR</p>
            <p>✔️ возможность официально устроиться на работу</p>
            <p>✔️ социальные льготы и программы</p>
            <p>DNI служит <i><strong>основным удостоверением личности</strong></i> в повседневной жизни в Аргентине. Его требуют при подписании контрактов, аренды жилья, получения водительских прав и многого другого. Даже для оплаты покупок в магазинах картой необходимо предъявить этот документ.</p>
            <p>Безвизовые путешествия в более чем 170 стран мира, включая страны Шенген, Великобританию, Японию, а также визу в США на 10 лет, можно получить имея именно <strong>паспорт</strong> Аргентины. <Link to="/blog/argentina-citizenship-after-giving-birth/"

                target="_blank" rel="noopener noreferrer">Процесс получения гражданства достаточно непредсказуем и не всегда так прост</Link>, как может казаться. Лучше всего обратиться к адвокатам за помощью. Они имеют ряд преимуществ в ведении дел о гражданстве в сравнении с обычными людьми.</p>

            <p>Еще есть один нюанс, о котором стоит упомянуть. Если вы получили перманентную резиденцию по воссоединению с семьей и потом покинули Аргентину на продолжительный срок, то Миграционная служба скорее всего отзовет ваши документы и процесс нужно будет запускать с самого начала. С этим все стало очень строго.</p>
        </div>
    )
};
export default fullText_parentsDocumentsExplained;