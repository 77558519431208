import React from 'react';

const fullText_choosingYourDoctor: React.FC = () => {
    return (
        <div>
            <p>Выбор врача - одна из первых задач, которую вам предстоит сделать после приезда в Аргентину. К этому выбору следует подойти очень ответственно, поскольку именно с этим человеком вам предстоит проделать огромную работу в день родов. Для того чтобы роды прошли как можно легче и спокойнее, вы должны на 100% доверять своему выбору.</p>
            <p>Первое знакомство с врачом проходит почти сразу после приезда в Аргентину. Вы можете познакомиться с несколькими врачами. На первый прием к врачу следует принести все результаты анализов и обследования от предыдущих врачей, которые у вас есть. Переводить эти документы не обязательно: на приеме можно будет воспользоваться или электронным переводчиком, или я переведу всю необходимую для врача информацию (если она на русском языке).</p>
            <p>Как узнать того самого врача?</p>
            <p>Врач будет максимально заинтересован в истории вашей беременности. Он задаст вам все уточняющие вопросы, изучит вашу обменную карту и результаты всех анализов. Хороший врач обязательно ответит на все ваши вопросы.</p>
            <p>Не стесняйтесь задавать вопросы. Вот список вопросов, которые помогут вам принять решение о сотрудничестве с врачом:</p>
            <p>1. Что входит в стоимость родов? Входят ли туда приемы до родов и КТГ?</p>
            <p>2. В какой момент родов вы приедете? Будете ли вы со мной на всем протяжении родов?</p>
            <p>3. Какое ваше отношение к эпидуральной анестезии? На каком этапе родов она применяется? Можно ли обойтись без нее в родах?</p>
            <p>4. Какой способ родоразрешения вы предпочитаете: естественные роды или кесарево сечение?</p>
            <p>5. Согласны ли вы оставаться на связи с нами на протяжении всего периода ведения беременности?</p>
            <p>6. Какие предродовые обследования и анализы вы назначаете?</p>
            <p>7. Какие рекомендации вы даете по поводу диеты и физических упражнений во время беременности?</p>
            <p>8. Как вы относитесь к составлению плана родов? Готовы ли вы обсудить и учесть мои предпочтения и пожелания во время родов?</p>
            <p>9. Какие послеродовые визиты и обследования вы проводите?</p>
            <p>10. Как часто вы применяете окситоцин и другие препараты во время родов?</p>
            <p>Я абсолютно уверена в профессионализме врачей, с которыми я сотрудничаю, и могу порекомендовать любого из них. А ваша задача - найти того самого врача, с которым вам будет максимально комфортно.</p>
        </div>
    )
}

export default fullText_choosingYourDoctor;