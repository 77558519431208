import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./OurClientsCarouselStyles.css"
import ImageSpinnerWrapper from '../../CustomImageWithSpinner';


class OurClientsCarousel extends React.Component {

    handleImageClick = (imageUrl: string) => {
        this.setState({
            showModal: true,
            selectedImage: imageUrl,
        });
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            selectedImage: null,
        });
    };

    render() {
        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 3000 },
                items: 3,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
            },
        };

        const imagePaths = [
            require('../../../assets/landingPage/photosFromClients/p_clients_1.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_2.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_25.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_12.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_24.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_5.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_6.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_8.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_7.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_9.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_11.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_10.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_14.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_13.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_15.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_16.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_19.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_18.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_20.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_21.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_23.jpg'),
            require('../../../assets/landingPage/photosFromClients/l_clients_22.jpg'),
            require('../../../assets/landingPage/photosFromClients/p_clients_17.jpg'),
        ];

        return (
            <div style={{ marginBottom: "0px" }}>
                <Carousel
                    responsive={responsive}
                    arrows={true}
                    draggable={false}
                    swipeable={true}
                    showDots={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2500}
                    keyBoardControl={false}
                    removeArrowOnDeviceType={[]}
                >
                    {imagePaths.map((imagePath, index) => (
                        <div key={index}>
                            <ImageSpinnerWrapper>
                                <img src={imagePath} className='our-clients-image' alt='Our client image' />
                            </ImageSpinnerWrapper>
                        </div>
                    ))}
                </Carousel>
            </div>
        );
    }
}

export default OurClientsCarousel;
