import { useEffect } from 'react';

const UseOgUrlMetaTag = (ogUrlContent: string) => {
    useEffect(() => {
        // Function to update or create a meta tag
        const updateMetaTag = (property: string, content: string) => {
            let existingTag = document.head.querySelector(`meta[property="${property}"]`);
            if (existingTag) {
                existingTag.setAttribute('content', content);
            } else {
                const newTag = document.createElement('meta');
                newTag.setAttribute('property', property);
                newTag.setAttribute('content', content);
                document.head.appendChild(newTag);
            }
        };

        // Update the og:url meta tag
        updateMetaTag('og:url', ogUrlContent + "/");
    }, [ogUrlContent]);
};

export default UseOgUrlMetaTag;
