import React, { useState } from 'react';
import './ToggleContactButtonStyles.css';
import { TbMessage } from "react-icons/tb";
import { RiCloseLargeLine } from "react-icons/ri";
import { ReactComponent as InstagramSVG } from '../assets/instagram.svg';
import { ReactComponent as WhatsappSVG } from '../assets/whatsapp.svg';
import { ReactComponent as TelegramSVG } from '../assets/telegram.svg';
import ContactDetailEnum from '../data/enums/ContactDetailsEnum';
import ReactGA from 'react-ga4';
import YandexMetrikaEnums from '../data/enums/YandexMetrikaEnum';


const ToggleContactButton: React.FC = () => {

    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (

        <div className="floating-button-container">

            {expanded && (
                <div className={`expanded-elements`}>
                    <a href={ContactDetailEnum.WhatsApp} target="_blank" rel="noopener noreferrer" data-text="Связаться в WhatsApp" className='floating-element-text' onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: `WhatsApp -> Круглая`
                        });
                        ym(YandexMetrikaEnums.id, 'reachGoal', 'WhatsApp');
                    }}>
                        <WhatsappSVG className="whatsappContactFloating floating-element" />
                    </a>
                    <a href={ContactDetailEnum.Instagram} target="_blank" rel="noopener noreferrer" data-text="Связаться в Instagram" className='floating-element-text' onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: `Instagram -> Круглая`
                        });
                        ym(YandexMetrikaEnums.id, 'reachGoal', 'Instagram');
                    }}>
                        <InstagramSVG className="instagramContactFloating floating-element" />
                    </a>
                    <a href={ContactDetailEnum.Telegram} target="_blank" rel="noopener noreferrer" data-text="Связаться в Telegram" className='floating-element-text' onClick={() => {
                        ReactGA.event({
                            category: 'User',
                            action: `Telegram -> Круглая`
                        });
                        ym(YandexMetrikaEnums.id, 'reachGoal', 'Telegram')
                    }}>
                        <TelegramSVG className="telegramContactFloating floating-element" />
                    </a>
                </div>
            )}

            {expanded ?
                <RiCloseLargeLine className="floating-button no-pulse-animation-only-shadow" onClick={toggleExpanded} /> :
                <TbMessage className="floating-button with-pulse-animation" onClick={toggleExpanded} />
            }
        </div>
    );
}

export default ToggleContactButton;;