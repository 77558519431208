import React, { useState } from 'react';
import {
    TelegramShareButton,
    WhatsappShareButton,
    TelegramIcon,
    WhatsappIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { ReactComponent as ViberSvg } from "../../assets/viber.svg";
import "./SocialShareStyles.css"

interface SocialShareProps {
    url: string;
    title: string;
    description?: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ url, title, description }) => {
    const [copied, setCopied] = useState(false);

    // Custom Viber share link
    const viberShareUrl = `viber://forward?text=${encodeURIComponent(title + ' ' + url)}`;

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    };

    return (
        <div className="social-share-container">

            <div className='social-share-text'>{`Поделиться
            статьей`}</div>

            <TelegramShareButton url={url} title={title}>
                <TelegramIcon size={32} round />
            </TelegramShareButton>

            <WhatsappShareButton url={url} title={title} separator=" ">
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            {/* Custom Viber Share Button */}
            <a href={viberShareUrl} target="_blank" rel="noopener noreferrer">
                <ViberSvg style={{ width: '32px', height: '32px', borderRadius: '50%' }} />
            </a>

            <CopyToClipboard text={url} onCopy={handleCopy}>
                <button style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    backgroundColor: '#f5f5f5',
                    cursor: 'pointer',
                    marginTop: '-3px'
                }}>
                    <FaCopy style={{ fontSize: '20px', color: copied ? "var(--rosa)" : "var(--verde)" }} />
                </button>
            </CopyToClipboard>
            {copied && <span className='copy-text'>Ссылка скопирована!</span>}
        </div>
    );
};

export default SocialShare;
