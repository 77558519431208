import React from 'react';
import OurPackages from '../../components/landingPageSections/packagesSection/OurPackages';

const fullText_comparingPrivateAndPublicHospitals: React.FC = () => {
    return (
        <div>
            <p>Аргентина известна своей бесплатной медициной. Особенность бесплатной медицины В том, что ею могут воспользоваться не только граждане и резиденты Аргентины, но и туристы. (К слову, это прилично сказывается на бюджете, поскольку в Аргентину едут люди из соседних стран, чтобы лечить такие сложные заболевания, как, например, рак. По той же причине в некоторых провинциях бесплатное медицинское обслуживание для туристов уже отменено) В Буэнос-Айресе есть возможность обратиться в бесплатную клинику для ведения беременности и дальнейших родов. В чем же принципиальная разница между родами в бесплатной и платной клинике? Давайте рассмотрим это в две стадии: во время ведения беременности и во время родов.</p>
            <h3>Ведение беременности</h3>
            <p>Главное преимущества платных госпиталей - это их гибкость. В частных госпиталях есть возможность выбора врача, с которым вы хотите вести беременность. Если один врач вам не подходит (по возрасту, полу, или другим причинам), то мы с вами всегда можем обратиться к другому врачу, который вам понравится. Это же относится и ко времени посещения врача. График работы будущих родителей может быть разным: кому-то удобно приходить на прием утром, кому-то после обеда. При выборе частного госпиталя можно выбрать врача, который время консультаций можно подобрать под ваш график, точно так же как и время УЗИ и время консультации кардиолога.</p>
            <p><i><strong>А вот анализы и в частной, и в государственной клинике принято сдавать утром, поскольку как правило сдавать кровь нужно натощак с перерывом в еде 12 часов.</strong></i></p>
            <p>В государственных госпиталях ситуация обстоит несколько иначе. Чтобы взять турно (талончик) к врачу, нужно приехать в больницу рано утром и отстоять очередь. Выбрать врача, у которого вы бы хотели наблюдаться, вряд ли получится, как и день и время посещения. Дата и время посещения будет вам выдано автоматически, а осматривать вас будет врач, который будет в тот момент на смене. Изменить что-то будет достаточно трудно.</p>
            <h3>Роды</h3>
            <p>Когда у вас начались роды (начались схватки или отошли воды) в частной клинике вы будете постоянно на связи с врачом и акушеркой, которые будут постоянно в курсе вашего состояния. Когда вы приедете в госпиталь, вас осмотрит акушерка, оценит ваше состояние, посмотрит раскрытие. Если вы приехали с маленьким раскрытием, то вас поместят в предродовую или родовую палату, где вы сможете проживать схватки до тех пор, пока не будет достаточное раскрытие. Рядом с вами могут находиться ваш муж или сопровождающий, которые помогут вам и поддержат в родах.</p>
            <p>При плановом кесарево мы с вами можем сами выбрать дату и время операции, которое всем будет удобно.</p>
            <p>Если вы приедете со схватками в государственную клинику, вас могут попросить подождать в очереди. А в случае маленького раскрытия вас отправят домой или “погулять” пока шейка матки достаточно не раскроется. К тому же в государственной больнице могут не разрешить присутствие отца и/или другого сопровождающего во время родов.</p>
            <p><i><strong>Все процедуры сразу после рождения малыша: осмотр, прививки, антибиотики для глазок от конъюнктивита - являются частью медицинского протокола и одинаковы во всех типах клиник.</strong></i></p>
            <h3>После родов</h3>
            <p>При выборе платных госпиталей вам не нужно беспокоиться о вещах для малыша во время пребывания в клинике. Подгузники, шапочку, одежду, белье на кроватку малыша и даже соску и смесь - все это входит в стоимость госпиталя, и вам не придется об этом беспокоится. Вам нужно взять только вещи для мамы и одежду на выписку для малыша. Как правило, средства личной гигиены тоже предоставляет клиника, как и трехразовое питание для мамы, которое можно выбрать из предложенного меню. Отец может все время быть рядом с мамой и малышом. В палатах есть диван, на котором он может спать.</p>
            <p>После выписки вы сможете выбрать удобное для вас время посещения неонатолога для осмотра малыша.</p>
            <p>Сумка в государственный роддом будет намного больше. Будущим родителям нужно позаботиться обо всем самим, иногда вплоть до простыней и подушек (да, бывают и такие госпитали). Сразу после родов на малыша наденут ту одежду, которую вы для него подготовите. Если вы ожидаете малыша зимой, то я вам советую позаботиться о пледе или одеяле для малыша в роддоме, поскольку там может быть достаточно холодно. Как правило, в государственных клиниках с вами в палате будет еще одна или несколько женщин, так что папе будет достаточно неудобно ночевать с вами.</p>
            <p>Принимать роды у вас будет тот врач, который будет в этот момент на дежурстве. Конечно, у него будут все ваши анализы и ваша история беременности. Но вот позвать на роды своего врача у вас вряд ли получится.</p>
            <p>При записи в неонатологию вам скажут только день, когда вам нужно прийти, но не время. Время ожидания в очереди к неонатологу может составить до 4‑х часов. Кроме того, врач может задержаться на другой работе или вообще не прийти, и придется переносить прием на другой день.</p>
            <h3>Документы на ребенка</h3>
            <p>В частных клиниках изготовление свидетельства о рождении малыша занимает до 7 дней. Это максимальный срок изготовления свидетельства, и зависит он от выбранной клиники. Сразу после того, как готово свидетельство, можно подать документы на первое DNI малыша в том же госпитале.</p>
            <p>В государственной больнице оформление документов занимает до 10 рабочих дней, и нужно искать, где оформить первый DNI на ребенка, поскольку там его сделать нельзя.</p>
            <p>Очевидным плюсом государственных клиник является их доступность. А частные госпитали отличаются гибкостью и комфортом пребывания после родов. Какую бы клинику вы ни выбрали, помните, что главное - это ваше здоровье и здоровье вашего малыша. Как в частных, так и государственных клиниках работают компетентные врачи, профессионалы своего дела.</p>
            <OurPackages />
        </div>
    )
};
export default fullText_comparingPrivateAndPublicHospitals;