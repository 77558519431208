import React, { useState } from 'react';
import './ReviewsModalStyles.css'; // Import modal styles
import { IoCloseCircleOutline } from "react-icons/io5";
import ImageSpinnerWrapper from './CustomImageWithSpinner';

interface ModalProps {
    imageUrl: string;
    onClose: () => void;
}

const ReviewsModal: React.FC<ModalProps> = ({ imageUrl, onClose }) => {
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content">
                <div className="close-btn" onClick={onClose}><IoCloseCircleOutline /></div>
                <ImageSpinnerWrapper>
                    <img src={imageUrl} alt="Enlarged review image" />
                </ImageSpinnerWrapper>

            </div>
        </div>
    );
};

export default ReviewsModal;
