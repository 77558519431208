import React from 'react';

const fullText_differencesBetweenDoulaAndMidwife: React.FC = () => {
    return (
        <div>

            <p>В родах, кроме близких людей и врача, женщине в родах могут помочь еще два человека: акушерка и доула. Какую роль в процессе родов играют эти два человека? Давайте разбираться. &nbsp;</p>
            <p>Начнем с акушерки. Акушерка - это человек обязательно с высшим медицинским образованием и большим опытом в акушерстве и гинекологии. Она играет большую роль не только в самих родах, но и на этапе подготовки. Именно акушерка знакомится с будущей мамой, рассказывает о беременности, о том, как изменяется тело во время беременности, как гормоны влияют на настроение, обо всех этапах родов и послеродовом восстановлении организма мамы. Кроме теоретических знаний, акушерка проводит практические занятия, на которых показывают как вести себя в родах, техники правильного дыхания, дают рекомендации как правильно вести себя во время схваток. На большом сроке, ближе к родам, акушерка даже может вести беременность и осматривать будущую маму без присутствия врача (но только при нормальном течении беременности без патологий): проверять вес, измерять окружность живота, высоту матки, прослушать сердцебиение ребенка, подключив аппарат КТГ, измерить давление беременной.</p>
            <p>Если врач не может присутствовать на приеме из-за операции или родов, то гинекологический осмотр беременной имеет право произвести акушерка: посмотреть раскрытие шейки матки, есть ли признаки готовящихся родов. Обычно это происходит на сроке 39-40 недель. Важно знать, что такой осмотр не может вызвать родовую деятельность, поскольку он является очень поверхностным. А вот роды на 39-40 неделе дело как раз очень распространенное.</p>
            <p>Кроме этого, в момент, когда начнутся роды, первый осмотр проведет именно акушерка. Без ее заключения вас не госпитализируют. Акушерка и только акушерка имею право смотреть раскрытие шейки матки (кроме врача, конечно же), поставить вам катетер и капельницу при необходимости. Она также может подсказать вам что нужно делать в момент схватки, какие позы лучше принять, поможет вам правильно дышать.</p>
            <p>Кто же такая доула, и какую роль она играет в родах? Говоря простым языком, доула играет роль гейши на родах и в процессе подготовки к ним. &nbsp;Начать сотрудничество с доулой можно с первых недель беременности, чтобы она помогла будущей маме пройти этот пусть с самого начала и до конца. В отличие от акушерки, доула может подготовить женщину к появлению малыша с психологической точки зрения.</p>
            <p>Одной из важных задач доулы во время подготовки к родам является смягчение страданий от гормональной перестройки организма женщины. Доула разговаривает с будущей мамой о ее роли, о том, какие изменения будут происходить в теле, фигуре и с гормонами девушки. Такая помощь особенно важна, если девушка ждет своего первенца.</p>
            <p>Кроме психологической помощи в подготовке к родам, доула расскажет вам про упражнения, которые помогут вам подготовиться к родам и успешно родить естественным путем. В вопросе подготовке к родам доула не обойдет стороной и вопрос грудного вскармливания: она расскажет, как подготовить грудь и соски, как за ними ухаживать, чтобы ребенку было легче взять грудь после рождения, расскажет о позах для кормления.</p>
            <p>Во время родов доула находиться с вами с первых схваток, оказывая психологическую поддержку: она подержит вас за руку, обнимет, скажет доброе слово. Она также может помочь вам пережить схватки, воздействуя на определенные точки &nbsp;на теле, предложит вам несколько поз для проживания схваток и помощи в раскрытии шейки матки.</p>
            <p>Важно! Как правило, доулы не имеют медицинского образования, а следовательно, не могут контролировать раскрытие шейки матки. Это делают только акушерка или врач.</p>
            <p>Доула следит, чтобы роженице было комфортно в родах (насколько это возможно). Она включит вам музыку, организует аромотерапию, приглушит свет.</p>
            <p>И акушерка, и доула являются незаменимыми помощниками, как в родах, так и на этапе подготовке к ним. Но важно помнить, что только акушерка может определить раскрытие шейки матки. Бывает так, что схватки очень интенсивные, и может показаться, что шейка достигла необходимого раскрытия. Но по приеду в клинику оказывается, что раскрытие составляет только 2-3 см.</p>
            <p>Помните о том, что в родах вам должно быть настолько комфортно, насколько это возможно. Роль доулы и акушерки очень похожи, но в то же время отличаются. Отнеситесь внимательно к выбору компаньона на роды.</p>
        </div>
    )
};
export default fullText_differencesBetweenDoulaAndMidwife;