import React from 'react';

const fullText_challengesInArgentinaToGiveBirth: React.FC = () => {
    return (
        <div>
            <p>Ведение беременности и роды заграницей - это важный шаг, требующий тщательного рассмотрения всех аспектов. Абсолютно все, кто занимается ведением родов в Аргентине, говорят о плюсах этой страны. Качественная медицина, климат, получение документов - это, безусловно, огромные достоинства, но неужели в этой затее нет никаких минусов? К сожалению, они тоже имеются.</p>
            <p>Основная сложность для семей, которые планируют прилететь в Аргентину (и не только для родов) - это, конечно же, <strong>языковой барьер</strong>. Основой язык в этой стране - это <i>испанский</i>. Хотя Буэнос-Айресе можно найти врачей, которые будут говорить на английском языке, незнание испанского языка может создать значительные трудности в общении с медицинским персоналом, особенно в <i>экстренных ситуациях</i>.</p>
            <p>Говоря о языковых различиях, нельзя не упомянуть <strong>культуру</strong> Аргентины. Культурные различия двух стран могут стать серьезным препятствием для комфортного пребывания в этой стране. Например, увидев беременную девушку на улице, прохожие запросто могут подойти к вам и начать расспрашивать о вашем прекрасном положении, трогая ваш беременный животик. А после родов прохожие запросто могут подойти к коляске и начать любоваться вашим малышом. Будьте готовы отвечать на вопросы о вашей беременности и ребенке от местных жителей.</p>
            <p>Еще один аспект, который следует учитывать при планировании родов в Аргентине - это <strong>адаптация к быту</strong> в чужой стране на другом континенте. <i>Магазины, общественный транспорт, розетки и даже продукты питания</i> - все это отличается на двух континентах. Продуктов, которые вы привыкли видеть на полках любого супермаркета, может оказаться сложно найти в магазинах Буэнос-Айреса, а такие продукты, как <i>творог, сметана, гречка, пельмени</i>, можно найти только в магазинах русских продуктов.</p>
            <p><strong>Медицинские риски</strong>. В Аргентине уровень медицины достаточно высокий, врачи постоянно повышают свою квалификацию, а медцентры оборудованы по последнему слову техники. Однако, во время пребывания в Буэнос-Айресе у ребенка или матери могут возникнуть <i>осложнения</i>, которые потребуют <strong>срочного лечения или медицинского вмешательства</strong>. В этом случае плохую шутку с вами может сыграть незнание языка и невозможность объяснится с медработниками в условиях стресса. Важно всегда иметь человека, который сможет вам <i><strong>помочь в экстренной ситуации</strong></i>.</p>
            <p><strong>Финансовые риски</strong>. Перелет, проживание, питание - все это повседневные расходы, без которых не обойтись. Также в бюджет на роды нужно запланировать расходы на клинику и врача. За время моей практики несколько пар меняли врача прямо перед родами, что несло за собой дополнительные расходы.</p>
            <p>Все эти вещи могут значительно осложнить пребывание в Аргентине. Поэтому важно отнестись к выбору <i>человека</i>, который будет вас сопровождать, <i><strong>с особой ответственностью</strong></i>.</p>

        </div>
    )
};
export default fullText_challengesInArgentinaToGiveBirth;