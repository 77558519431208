import React from 'react';
import { useInView } from 'react-intersection-observer';
import './ScrollAnimationStyles.css';  // Create a CSS file for styling

interface ScrollAnimationProps {
    children: React.ReactNode;
    customClassName: string;
    direction: 'left' | 'right' | 'up' | 'down';  // Add direction prop
    displayedThreshold?: number,
}

const ScrollAnimation: React.FC<ScrollAnimationProps> = ({ children, customClassName: className, direction = 'left', displayedThreshold = 0.70 }) => {
    const { ref, inView } = useInView({
        threshold: displayedThreshold, // Adjust this value as needed
        triggerOnce: true, // Trigger only once
    });

    return (
        <div
            ref={ref}
            className={`${className} ${inView ? `slide-in-${direction}` : `slide-out-${direction}`}`}
        >
            {children}
        </div>
    );
};

export default ScrollAnimation;
