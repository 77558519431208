// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, Route, Routes, useParams } from 'react-router-dom';
import BlogArticleModel from '../../models/BlogArticleModel';
import './BlogArticleFullStyles.css';
import BlogPage from '../../pages/BlogPage';
import { IoChevronBackCircle } from "react-icons/io5";
import RandomPosts from './RandomPosts';
import ImageSpinnerWrapper from '../CustomImageWithSpinner';
import SiteMetaPropertyEnum from '../../data/enums/SiteMetaPropertiesEnum';
import useTitleAndOgTitleMetaTag from '../UseTitleAndOgTitleMetaTag';
import UseDescriptionAndOgDescriptionMetaTag from '../UseDescriptionAndOgDescriptionMetaTag';
import UseOgUrlMetaTag from '../UseOgUrlMetaTag';
import UseCanonicalLinkTag from '../UseCanonicalLinkTag';
import UseOgTypeMetaTag from '../UseOgTypeMetaTag';
import NotFound from '../../pages/NotFoundPage';
import SocialShare from './SocialShare';
import ReactGA from 'react-ga4';
import ScrollAnimation from '../ScrollAnimation';
import LoadingBlogPage from '../../pages/LoadingBlogPage';

interface BlogArticleFullProps {
    blogArticles: BlogArticleModel[];
}

// Define your BlogArticleFull component
const BlogArticleFull: React.FC<BlogArticleFullProps> = (props) => {

    // Access route parameters
    const { id } = useParams();
    let dummyArticle: BlogArticleModel = {
        blogArticleURL: "",
        blogImageURL: "buenos-aires-summer.jpg",
        datePublished: "",
        articleTitle: "",
        articleSummary: "",
        articleComponent: LoadingBlogPage,
        tags: [],
        articleH1: ""
    }

    // State to hold the fetched data
    const [article, setArticle] = useState<BlogArticleModel>(dummyArticle);

    // Effect to fetch data based on the ID
    useEffect(() => {
        // Search for the article in the array based on the id (blogArticleURL)
        const foundArticle = props.blogArticles.find(a => a.blogArticleURL === id);

        // If article is found, set it to state
        if (foundArticle) {
            setArticle(foundArticle);
        } else {
            // Handle case when article is not found
            console.error('Article not found');
        }
    }, [id, props.blogArticles]); // Fetch data whenever the ID or BlogArticlesArrayData changes

    //upon clicking on a blog article from Blog page, scroll to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useTitleAndOgTitleMetaTag(`${article.articleTitle}`, `${article.articleTitle}`)

    UseDescriptionAndOgDescriptionMetaTag(article.articleSummary, article.articleSummary);

    UseOgUrlMetaTag(`${SiteMetaPropertyEnum.URLBlogSite}/${article.blogArticleURL}`);
    UseOgTypeMetaTag(SiteMetaPropertyEnum.OgTypeBlogArticle);
    UseCanonicalLinkTag(`${SiteMetaPropertyEnum.URLBlogSite}/${article.blogArticleURL}`);

    function convertDateString(inputDate: string): string {
        // Split the input date string by '/'
        const [day, month, year] = inputDate.split('/');

        // Rearrange the date components to "YYYY-MM-DD"
        const formattedDate = `${year}-${month}-${day}`;

        // Add the time component "01:00"
        const dateTime = `${formattedDate} 01:00`;

        return dateTime;
    }

    // Render the article
    return (
        <article>
            {article ? (
                <div className="fullBlogArticle">
                    <div className='full-blog-header-container'>
                        <ScrollAnimation direction={'up'} customClassName='replace-slide-animation'>
                            <ImageSpinnerWrapper>
                                <img src={require(`../../blogPosts/images/${article.blogImageURL}`)} className='blog-image' alt='blog-top-image' />
                            </ImageSpinnerWrapper>
                        </ScrollAnimation>
                        <div className='full-blog-header-breadcrumb'>
                            <Link to={`/`} onClick={() =>
                                ReactGA.event({
                                    category: 'User',
                                    action: `Из blog breadcrumb -> Главная'`
                                })
                            }>Главная</Link>
                            <div> {` > `} </div>
                            <Link to={`/blog/`} onClick={() =>
                                ReactGA.event({
                                    category: 'User',
                                    action: `Из blog breadcrumb -> Блог'`
                                })
                            }>Блог</Link>
                            <div> {` > `} </div>
                            <div className='full-blog-header-breadcrumb-current-title'>{article.articleH1}</div></div>

                        <div className='full-blog-header-text-container'>
                            <ScrollAnimation direction={'down'} customClassName='replace-slide-animation'>
                                <h1>{article.articleH1}</h1>

                                <h2 className='site-name-in-blog'>{SiteMetaPropertyEnum.PlainSiteTitle}</h2>
                                <div className='date-published'><time dateTime={convertDateString(article.datePublished)}
                                >{article.datePublished}</time></div>
                            </ScrollAnimation>
                        </div>

                    </div>

                    <p style={{ textAlign: "right", fontSize: "13px" }}>Designed by <a href="https://www.freepik.com/">Freepik</a></p>
                    <div className='fullArticleText'><article.articleComponent /></div>
                    <div className='article-end-back-buttons-container '>
                        <div className='back-button'><NavLink to="/blog/"><IoChevronBackCircle className='back-button-arrow-icon' />Блог</NavLink></div>
                        {/* <div className='back-button'><NavLink to="/"><IoChevronBackCircle className='back-button-arrow-icon' />Cайт</NavLink></div> */}
                    </div>
                    <SocialShare url={SiteMetaPropertyEnum.URLBlogSite + "/" + article.blogArticleURL + "/"} title={article.articleTitle} description={article.articleSummary} />
                    <hr />
                    <div className='other-blog-articles-text'>Другие статьи из Блога:</div>
                    <RandomPosts isFilteringCurrentArticle={true} currentArticleId={id as string} />

                </div>
            ) : (
                <p>Загружаемся...</p>
            )}


            <Routes>
                {/* <Route path="/" element={<HomePage
                    forwardedRefAboutMe={sectionRefAboutMe}
                    forwardedRefAccompanying={sectionRefAccompanying}
                    forwardedRefOurClients={sectionRefOurClients}
                    forwardedRefHowAccompanying={sectionRefHowAccompanying}
                    forwardedRefReviewsFromClients={sectionRefReviewsFromClients}
                    forwardedRefQuestionAnswer={sectionRefQuestionAnswer} />} /> */}
                <Route path="/blog/" element={<BlogPage />} />
            </Routes>
        </article>
    );
};

export default BlogArticleFull;
