import { useEffect } from 'react';

const useTitleAndOgTitleMetaTag = (title: string, ogTitleContent: string) => {
    useEffect(() => {
        document.title = title;
        // Update the meta tag in the document head
        const existingOGDescriptionTag = document.head.querySelector('meta[property="og:title"]');
        if (existingOGDescriptionTag) {
            existingOGDescriptionTag.setAttribute('content', ogTitleContent);
        } else {
            const newOGDescriptionTag = document.createElement('meta');
            newOGDescriptionTag.setAttribute('property', 'og:title');
            newOGDescriptionTag.setAttribute('content', ogTitleContent);
            document.head.appendChild(newOGDescriptionTag);
        }
    }, [title, ogTitleContent]);
};

export default useTitleAndOgTitleMetaTag;
