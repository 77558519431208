import React from 'react';
import navigationServices from '../../services/navigationServices';
import { Link } from 'react-router-dom';
import OurPackages from '../../components/landingPageSections/packagesSection/OurPackages';

const fullText_howAccompanyingWorks: React.FC = () => {
    return (
        <div>
            <p>Когда вы приняли решение о родах в Аргентине, у вас может возникнуть множество вопросов и сложностей. Какие документы надо приготовить, где купить детские вещи, есть ли в Аргентине привычные нам продукты, марки, товары, как менять валюту, как пользоваться транспортом - вот далеко не полный список вопросов, которые мне задают каждый день. Есть два способа найти ответы на эти вопросы:</p><ol><li>искать в интернете, в бесконечных сообщениях телеграм-чатов</li><li><Link to="/"

                target="_blank" rel="noopener noreferrer">начать сотрудничество с нами</Link>, и получить ответы на эти вопросы в кратчайшие сроки от меня или моей команды</li></ol>
            <p>Итак, вы приняли решение лететь в Аргентину, <i>выбрали самое заботливое сопровождение родов</i> и подписали с нами договор о сотрудничестве. В этот момент я и моя команда начинаем работу с вами, и не важно, в какой стране мира вы находитесь. Мы поможем вам подобрать авиабилеты, расскажем о документах, которые необходимо взять с собой в Аргентину, поможем собрать чемодан и сумку для ребенка, подскажем, какие вещи нужно привести с собой, а какие можно купить на месте, поможем с подбором отеля на первое время. С момента начала сотрудничества вы можете задавать абсолютно любые вопросы. Мы всегда на связи с вами.</p>
            <p>В день прилета мы с водителем будем ждать вас в аэропорту и отвезем в отель или в квартиру, которую вы арендовали. С собой мы привезем необходимые вещи на первое время: <i>переходники для розеток, сим-карты,</i> которыми вы сможете пользоваться почти сразу, <i>воду</i> и многое другое. Кроме того мы привезем вам <i>обмен денег</i> на первое время.</p>
            <p>Почти сразу после вашего приезда, мы организуем <strong>встречи с врачами</strong> и <strong>экскурсии по клиникам</strong>, которые могут вас заинтересовать. Кроме того, мы рекомендуем вам несколько <strong>риелторов</strong> для ускорения поиска квартиры и проведем <strong>адаптационную встречу</strong> с нашей командой, где расскажем о тонкостях жизни в Аргентине и ответим на все ваши вопросы.</p>
            <p>На первом приеме ваш доктор назначит вам все <i>необходимые анализы и обследования</i>. Я буду сопровождать вас на всех этих процедурах. Все необходимые направления и талончики для вас я возьму сама, и вам не придется висеть на телефоне, чтобы записаться к врачу. Это время вы сможете посвятить себе.</p>
            <p>Мы остаемся с вами на связи 24/7. Вы можете задать нам любой вопрос. Не знаете, где купить какао? Мы поможем. Столкнулись с бытовой проблемой? Мы поможем. Есть проблемы со здоровьем у вас или у члена вашей семьи? Мы поможем. Будь то магазины для малышей, продуктовые магазины, магазины одежды, техники или канцтоваров - мы поможем вам ответить на любой вопрос. Мы также поможем организовать экскурсии по Буэнос-Айресу, в Тигре и Люхан, если вы не хотите скучать в ожидании дня родов.</p>
            <p>Если вы столкнулись с <strong>проблемами здоровья</strong>, вам не нужно искать врачей в бесконечных сообщениях многочисленных чатов Аргентины. Напишите мне, и я найду вам врача и, конечно же, сопровожу вас к нему. А если произошла ситуация, которая требует <strong>немедленного медицинского вмешательства</strong>, я вызову <i>скорую</i> и <i>буду оставаться с вами на связи</i>. <i><strong><u>Я никогда не оставлю вас один на один с вашими трудностями</u></strong></i>.</p>
            <p><strong>В момент “Х”</strong> я буду с вами с самого начала и до перевода в послеродовую палату. Я также все время буду поддерживать связь с вашим врачом и/или акушеркой до приезда в госпиталь.</p>
            <p>После рождения малыша, наше сотрудничество не прекращается. Я сопровождаю вас на прием к неонатологу для осмотра малыша, а также к врачу для послеродового осмотра мамы. Мы также поможем вам оформить <Link to="/blog/kids-documents-explained/"

                target="_blank" rel="noopener noreferrer">документы на ребенка</Link>, включая свидетельство о рождении, DNI и паспорт. Если вы решите остаться в Аргентине, мы поможем с легализацией и получением <Link to="/blog/parents-documents-explained/"

                    target="_blank" rel="noopener noreferrer">DNI для родителей</Link>.</p>
            <p>Со мной и моей командой вы никогда не будете чувствовать себя одиноко и неуверенно в чужой стране. Вы можете быть уверены, что мы поможем вам в любой ситуации.</p>

            <OurPackages />
        </div>
    )
};
export default fullText_howAccompanyingWorks;