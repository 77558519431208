import React from 'react';
import OurPackages from '../../components/landingPageSections/packagesSection/OurPackages';

const fullText_partnerBirth: React.FC = () => {
    return (
        <div>
            <p>Аргентина — страна партнерских родов. Присутствие партнера на родах в Аргентине считается обязательным. Для этого есть одна веская причина: <strong>после рождения ребенок не должен быть один или пропадать из вида родителей ни на секунду.</strong> Это сделано для того, чтобы сами родители были уверены, что ребенку ничего лишнего не вколют, не поставят прививку, если этого делать нельзя, и что ребенка не перепутают.</p>
            <p>Отцу или другому партнеру (маме, подруге или другому человеку) необязательно сдавать анализы для того, чтобы присутствовать на родах. В день родов будущие родители приходят вместе в госпиталь и оформляют все необходимые документы. Роженице необходимо переодеться в больничную накидку. Мужу, так же как и остальным сопровождающим (переводчику, доуле), необходимо переодеться в специальную стерильную униформу или халат (зависит от клиники), а вещи оставить в специальных ящиках или палате. <strong>Родовые палаты стерильны, поэтому переодеваться всем участникам процесса обязательно.</strong></p>
            <p>В родовой палате будущий папа помогает жене пережить схватки, поддерживая ее, делая массаж, может принести водичку, или просто держать за руку.&nbsp;</p>
            <p><i>Особо заботливые отцы могут даже вздремнуть на кровати, пока мама на фитболе.</i>&nbsp;</p>
            <p>Поддержка отца крайне важна вплоть до самого рождения малыша. Однако не все могут выдержать самый ответственный момент рождения ребенка. В этом случае отец может выйти на время, а потом вернуться. Кстати, иногда сами роженицы просят мужа выйти в этот момент, чтобы будущий папа точно не упал в обморок.</p>
            <p>В случае естественных родов, отец даже может сам <i>перерезать пуповину</i> (конечно, если будет желание это сделать). Для этого нужно предупредить бригаду врачей о своем намерении это сделать заранее и надеть стерильные перчатки. Нужно ли говорить, что не многие отцы на это решаются.</p>
            <p>В случае кесарево сечения отец тоже может быть рядом с мамой. Он находится рядом, поддерживает жену, держит за руку. В этом случае, конечно, отцу перерезать пуповину будет невозможно.</p>
            <p>После рождения ребенка папа проходит с ребенком в специальный зал для <i>первого неонатального осмотра</i>. У малыша проверяют глаза, слушают легкие, проверяют, нет ли дисплазии, оценивают сосательный рефлекс. После осмотра неонатолог измеряет рост, окружность головы и вес ребенка. Во время всех этих манипуляций <strong>папа находится рядом</strong>. У него спрашивают разрешение поставить прививки, витамин К и закапать глазки антибиотиком для предотвращения конъюнктивита.</p>
            <p>После осмотра и прививок малыша одевают и укутывают. Папа подписывает документы, сверяет фамилию и имя на браслетах ребенка и матери, также подписывает специальный акт рождения, где подтверждает, что ребенок является совместным ребенком его и жены. Только после этого они могут пройти в послеродовую палату к маме.</p>
            <p>Редко, но бывают такие случаи, когда отец не может присутствовать на родах. В этом случае его роль может выполнять акушерка. Но в Аргентине такая практика является очень редкой. Очень редко женщина бывает в родах совсем одна. Если отец не может присутствовать, на роды можно взять другого помощника (партнера), например, <i>маму или подругу</i>. <strong>В таком случае роженица не будет совсем одна, она ощутит поддержку родных, а роды все равно будут считаться партнерскими.</strong></p>
            <OurPackages />
        </div>
    )
}

export default fullText_partnerBirth;