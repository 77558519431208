import React from 'react';
import { Link } from 'react-router-dom';

const fullText_documentsDeliveryPostalService: React.FC = () => {
    return (
        <div>
            <p>Одной из особенностей Аргентины заключается в порядке работы почты и курьеров: они никогда не поднимаются на этаж. Если вы ждете посылку, доставку еды, покупку из интернет-магазина, когда курьер позвонит в дверь, вам придется спуститься к нему вниз, чтобы забрать доставку.</p>
            <p>Это особенно важно, когда вы ожидаете <Link to="/blog/kids-documents-explained/"
                target="_blank" rel="noopener noreferrer">документы для малыша</Link> или <Link to="/blog/parents-documents-explained/"
                    target="_blank" rel="noopener noreferrer">документы для вас</Link>. Документы (если они были сделаны не в срочном порядке) доставляет домой почтальон. Отследить прогресс изготовления документов можно <a target="_blank" rel="noopener noreferrer" href="https://mitramite.renaper.gob.ar/">на сайте Renaper</a>.</p>
            <p>Если вы знаете, что сегодня к вам должен прийти почтальон с документами, я рекомендую вам оставаться дома пока документы вам не принесут. Когда вам в дверь позвонит почтальон, скажите ему, что вы спускаетесь (voy abajo - бой авахо), и идите вниз. Не забудьте взять с собой ваш паспорт и квитанцию (которую вам давали при подаче документов в Renaper).</p>
            <p>Что делать, если обстоятельства сложились так, что вы пропустили почтальона, или он решил вас не дожидаться?</p>
            <p>Конечно, документы вы получите. Но это займет немного больше времени.</p>
            <p>Попытка доставить документ почтальоном будет еще два раза. Все успешные или неуспешные попытки доставки документов будут фиксироваться в статусе <a target="_blank" rel="noopener noreferrer" href="https://www.correoargentino.com.ar/seguimiento-de-envios/">на сайте почты Аргентины</a>.</p>
            <p>А если и после трех попыток доставки встретиться с почтальоном не получилось, то вам придется обращаться на почту для получения заветного документа.</p>
            <p>Я всегда стараюсь оформлять документы для малыша в срочном порядке за несколько часов. В этом случае DNI и паспорт малыша выдадут родителям прямо на руки, а необходимость сидеть дома в ожидании почтальона отпадает автоматически.</p>
            <p>С документами для родителей же ситуация немного иная: до того, как Renaper запустит изготовление DNI для родителей, его должна одобрить миграционная служба. Срок рассмотрения дела на выдачу DNI для родителей сейчас занимает около 4-х месяцев (если вам повезет, могут выдать и раньше). После одобрения документов миграционной службой они отправляются в Renaper, и с этого момента процесс отслеживания прогресса изготовления документов становится таким же, как и для документов малыша.</p>
        </div>
    )
};
export default fullText_documentsDeliveryPostalService;