import { useEffect } from 'react';

const UseCanonicalLinkTag = (canonicalUrl: string) => {
    useEffect(() => {
        // Function to update or create a link tag
        const updateLinkTag = (rel: string, href: string) => {
            let existingTag = document.head.querySelector(`link[rel="${rel}"]`);
            if (existingTag) {
                existingTag.setAttribute('href', href);
            } else {
                const newTag = document.createElement('link');
                newTag.setAttribute('rel', rel);
                newTag.setAttribute('href', href);
                document.head.appendChild(newTag);
            }
        };

        // Update the canonical link tag
        updateLinkTag('canonical', canonicalUrl + "/");
    }, [canonicalUrl]);
};

export default UseCanonicalLinkTag;