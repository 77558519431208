import React from 'react';
import './InterestingArticlesStyles.css';
import RandomPosts from '../../blog/RandomPosts';

interface InterestingArticlesProps {
    excludeArrayOfSpecificPostURLs?: string[]
}

const InterestingArticles: React.FC<InterestingArticlesProps> = (props) => {
    return (
        <section className='interesting-articles-section'>
            <h2 className='interesting-articles-section-heading'>Другие интересные статьи</h2>
            <div>
                {(props.excludeArrayOfSpecificPostURLs?.length === undefined &&
                    <RandomPosts isFilteringCurrentArticle={false} currentArticleId={''} key={"landing-random-blog-posts"} />
                )}
                {(props.excludeArrayOfSpecificPostURLs?.length !== undefined &&
                    <RandomPosts isFilteringCurrentArticle={false} currentArticleId={''} key={"landing-random-blog-posts"} excludeArrayOfSpecificPostURLs={props.excludeArrayOfSpecificPostURLs} />
                )}
            </div>
        </section>
    );
};

export default InterestingArticles;