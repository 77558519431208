import React, { useEffect, useState } from 'react';
import BlogPostTagEnum from '../data/enums/BlogPostsTagEnum';
import blogArticles from "../blogPosts/blogArticlesArrayData";
import BlogArticlePreview from '../components/blog/BlogArticlePreview';
import './BlogPageStyles.css';
import SiteMetaPropertyEnum from '../data/enums/SiteMetaPropertiesEnum';
import useTitleAndOgTitleMetaTag from '../components/UseTitleAndOgTitleMetaTag';
import ReactGA from 'react-ga4';
import UseDescriptionAndOgDescriptionMetaTag from '../components/UseDescriptionAndOgDescriptionMetaTag';
import UseOgUrlMetaTag from '../components/UseOgUrlMetaTag';
import useTrailingSlash from '../components/UseTrailingSlash';
import UseOgTypeMetaTag from '../components/UseOgTypeMetaTag';
import UseCanonicalLinkTag from '../components/UseCanonicalLinkTag';

const BlogPage: React.FC = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    const [selectedTags, setSelectedTags] = useState<BlogPostTagEnum | null>(null);

    const handleTagSelect = (tag: BlogPostTagEnum) => {
        setSelectedTags(selectedTags === tag ? null : tag);
    };

    const filteredBlogArticles =
        blogArticles.filter((b) =>
            (!selectedTags || b.tags.includes(selectedTags))
        );

    useEffect(() => {
        // Create a style element
        const styleElement = document.createElement('style');

        // Define your CSS rules
        const css = `
              body {
                background-color: white;
              }
            `;

        // Set the CSS text of the style element
        styleElement.innerHTML = css;

        // Append the style element to the head of the document
        document.head.appendChild(styleElement);

        // Clean up function to remove the style element when component unmounts
        return () => {
            document.head.removeChild(styleElement);
        };
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    useTrailingSlash();

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when component mounts
    }, []);

    useTitleAndOgTitleMetaTag(SiteMetaPropertyEnum.BlogPageSiteTitle, SiteMetaPropertyEnum.BlogPageSiteTitle);

    UseDescriptionAndOgDescriptionMetaTag(SiteMetaPropertyEnum.BlogSiteDescription, SiteMetaPropertyEnum.BlogSiteDescription);

    UseOgUrlMetaTag(SiteMetaPropertyEnum.URLBlogSite);
    UseOgTypeMetaTag(SiteMetaPropertyEnum.OgTypeWebsite);
    UseCanonicalLinkTag(SiteMetaPropertyEnum.URLBlogSite)

    return (
        <div className='whole-blog-page-container'>
            <div className='blog-page-heading-container'>
                <h1 className='blog-page-heading'
                    data-testid="blog-name-main-heading blog-title">Блог Ольги про роды в Аргентине</h1><div className='blog-title-tagline'>Заметки и наблюдения о жизни в Аргентине, особенности родов, советы, нюансы, быт</div>
            </div>
            <div>
                {/* <h3>Фильтровать статьи по тегам</h3> */}
                <div className="blog-tags-container">
                    {/* Display tags and handle selection */}
                    {Array.from(new Set(blogArticles.flatMap(b => b.tags))).map(tag => (
                        <button
                            key={tag}
                            onClick={() => handleTagSelect(tag)}
                            style={{
                                backgroundColor: selectedTags === tag ? 'var(--rosa)' : 'white',
                                color: selectedTags === tag ? 'white' : 'black',
                                border: 'none',
                                borderRadius: '5px',
                                padding: '5px 10px',
                                margin: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            {tag}
                        </button>
                    ))}
                </div>

                <div className='containerForBlogArticles'>
                    {filteredBlogArticles.length === 0 ? (
                        <p>Нет статей, которые попадают под выбранные фильтры</p>
                    ) : (
                        filteredBlogArticles.map((articlePreview) => (
                            <BlogArticlePreview key={articlePreview.blogArticleURL} blogArticleModel={articlePreview} />
                        ))
                    )}
                </div>

            </div>
        </div >
    );
};

export default BlogPage;