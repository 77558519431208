import React from 'react';

const fullText_interestingFactAboutBabysLastName: React.FC = () => {
    return (
        <div>
            <p>Поделюсь с вами интересным фактом о фамилии малыша.</p>

            <p>Бывает так, что если у родителей разная фамилия, то малышу в роддоме дадут фамилию матери.</p>

            <p>При рождении девочек, это не является большой проблемой.</p>

            <p>Но если у вас родился  мальчик, то он тоже будет с фамилией матери. Например, Иванова Иван.</p>

            <p>Сейчас во время родов в госпитале все чаще спрашивают родителей, какая фамилия будет у малыша, и в документах пишут сразу правильно.</p>

            <p>Но, если в госпитале при рождении записали фамилию матери, переживать не стоит. При оформлении Свидетельства о рождении нужно попросить переводчика, чтобы фамилию сменили.</p>

        </div>
    )
};
export default fullText_interestingFactAboutBabysLastName;