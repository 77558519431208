import React, { useEffect, useRef, useState } from 'react';
import PackageInformationStandard from './PackageInformationStandard';
import { FaCar } from "react-icons/fa";
import { MdMoreTime } from "react-icons/md";
import { BsPersonVcard } from "react-icons/bs";
import "./PackageInformationPremiumStyles.css";
import { FaPlus } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import ReactGA from 'react-ga4';

interface PremiumPackageProps {
    sendHeightDataToParent: (data: number) => void;
    sendIsExpandedDataToParent: (data: boolean) => void;
}

const PackageInformationPremium: React.FC<PremiumPackageProps> = (props) => {

    const [isExpandedStandard, setIsExpandedStandard] = useState(false);
    const standardPackageRef = useRef(null as any);
    const [contentHeight, setContentHeight] = useState(0);

    const toggleStandardDetails = () => {
        ReactGA.event({
            category: 'User',
            action: `Услуги Ст v Пр - ${(isExpandedStandard ? "Свернуть" : "Раскрыть")}`
        });
        setIsExpandedStandard(!isExpandedStandard);
        measureContentHeight();
        props.sendHeightDataToParent(contentHeight);
        props.sendIsExpandedDataToParent(isExpandedStandard);
    };

    const measureContentHeight = () => {
        try {
            setContentHeight(standardPackageRef.current.scrollHeight);
            props.sendHeightDataToParent(contentHeight);
        } catch (e: any) {
            setContentHeight(0);
            // contentHeight = standardPackageRef.current.scrollHeight;
        }
    };


    useEffect(() => {
        if (standardPackageRef === undefined) { }
        else if (standardPackageRef === null) { }
        else {
            measureContentHeight();
        }
        props.sendIsExpandedDataToParent(isExpandedStandard);

    }, [isExpandedStandard]);

    // Update content height when content changes
    useEffect(() => {
        if (standardPackageRef === undefined) { }
        else if (standardPackageRef === null) { }
        else {
            measureContentHeight();
        }
    }, [standardPackageRef.current]);

    useEffect(() => { }, [contentHeight]);

    useEffect(() => { }, [isExpandedStandard])

    return (
        <div className='expandedPremiumPackage' >
            <div style={{ color: '#FFEAEA' }} className='premiumPackageHeading'><FaStar className={`premiumPackageHeadingIcon`} />Все позиции пакета стандарт</div>

            <div className='expandStandardPackageInPremiumContainer'>
                <button onClick={toggleStandardDetails} className='expandStandardPackageInPremium'>{!isExpandedStandard ? <IoIosArrowDown className='expandStandardPackageInPremiumIcon' /> : <IoIosArrowUp className='expandStandardPackageInPremiumIcon' />}  Услуги Пакета Стандарт</button>
            </div>

            <div className={`detailsStandard ${isExpandedStandard ? 'open' : 'closed'}`} style={{
                height:
                    !isExpandedStandard ?
                        "0px" :
                        `${contentHeight}px`
            }}
                ref={standardPackageRef}>
                <PackageInformationStandard isEmbeddedInPremium={true} />
            </div>

            {/* <FaPlus className="premiumPackagePlusIcon" /> */}

            <div className='premiumPackageHeading aboveStandardPackage'><FaCar className='premiumPackageHeadingIcon' />Предоставим автомобиль в день выписки</div>

            <div className='premiumPackageHeading'><MdMoreTime className='premiumPackageHeadingIcon' />Оформление документов для ребенка в срочном порядке
            </div>

            <div className='premiumPackageHeading'><BsPersonVcard className='premiumPackageHeadingIcon' />Оформление DNI для родителей </div>
            <div style={{ color: 'white' }} className='package-item-text'>Оформление DNI для других членов семьи <strong>не входит</strong> в пакет Премиум</div>

        </div>
    );
};

export default PackageInformationPremium;